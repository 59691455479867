import { Backdrop, Box, Breadcrumbs, Button, CircularProgress, Divider, Link, Snackbar, Tab, Typography, Tabs, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, CardHeader, Accordion, AccordionSummary, AccordionDetails, Dialog, AppBar, IconButton, Toolbar, DialogContent, DialogActions, Autocomplete, Checkbox, FormGroup, Paper, Zoom } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef, useCallback } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionProps } from '@mui/material/transitions';
import Grid from '@mui/material/Grid2';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import RecyclingIcon from '@mui/icons-material/Recycling';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom in={true} ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Aktor {
    Navn: string;
    Besoksadresse: string;
    Besokspostnummer: string;
    Besokssted: string;
    Mobilnummer: string;
    Epost: string;
    OrgNummer: string;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface UploadProgressType {
    [key: string]: {
        progress: number;
        speed: number;
        timeRemaining: string;
    };
}

function bytesToSize(bytes: number) {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    if (bytes === 0) return '0 Bytes';

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + units[i];
}

function InnsendingDetaljer() {
    const { aktorid } = useParams();
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingInfo, setisLoadingInfo] = useState("");
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [aktor, setAktor] = useState<Aktor | null>(null);
    const [aktorEier, setAktorEier] = useState<Aktor | null>(null);
    const [ModalPlombeBrudd, setModalPlombebrudd] = useState(false);
    const token = sessionStorage.getItem("jvtoken") || "";
    const [AktivOrdreId, setAktivOrdreId] = useState("0");
    const [instrumenterGruppert, setInstrumenterGruppert] = useState<Record<string, InstrumentGruppert>>({});
    const [instrumenterGruppertKasserte, setInstrumenterGruppertKasserte] = useState<Record<string, InstrumentGruppert>>({});
    const [AktivtInstrument, setAktivtInstrument] = useState(0);
    const [AktivtInstrumentIndex, setAktivtInstrumentIndex] = useState(0);
    const [ModalAktivePlomber, setModalAktivePlomber] = useState<any[]>([]);
    const [ModalHistorikk, setModalHistorikk] = useState(false);
    const [ModalKasserInstrument, setModalKasserInstrument] = useState(false);
    const [ModalReaktiverInstrument, setModalReaktiverInstrument] = useState(false);
    const [AktivInstrumentType, setAktivInstrumentType] = useState("");
    const [instrumenter, setInstrumenter] = useState<Instrument[] | null>(null);
    const [instrumenterKasserte, setInstrumenterKasserte] = useState<Instrument[] | null>(null);
    const [instrumentTyper, setInstrumentTyper] = useState<any[]>([]);
    const [instrumentDelKategorier, setInstrumentDelKategorier] = useState<any[]>([]);
    const [internkontroll, setInternkontroll] = useState<any[]>([]);
    const nyInstrumentTypeRef = useRef<any>([]);
    const [Fabrikater, setFabrikater] = useState<any[]>([]);
    const nyFabrikatRef = useRef<any>([]);
    const [Modeller, setModeller] = useState<any[]>([]);
    const nyModellRef = useRef<any>([]);
    const [ModalEndreMerking, setModalEndreMerking] = useState(false);
    const [ModalMelding, setModalMelding] = useState(false);
    const nyPlombeNavnRef = useRef<any>([]);
    const nyPlombeTypeRef = useRef<any>([]);
    const nyPlombeNummerRef = useRef<any>([]);
    const nyPlombePlasseringRef = useRef<any>([]);
    const [NyePlomber, setNyePlomber] = useState<any[]>([]);
    const [plombeSlett, setPlombeSlett] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [ModalInstrumentDelNy, setModalInstrumentDelNy] = useState(false);
    const [ModalInstrumentDelSlett, setModalInstrumentDelSlett] = useState(false);
    const [InstrumentMerking, setInstrumentMerking] = useState("");
    const [AktivInstrumentDelId, setAktivInstrumentDelId] = useState(0);
    const [AktivInstrumentDelIndex, setAktivInstrumentDelIndex] = useState(0);
    const [Benevninger, setBenevninger] = useState<any[]>([]);
    const [nyPlombeNavn, setNyPlombeNavn] = useState('');
    const [nyPlombeType, setNyPlombeType] = useState('');
    const [nyPlombeNavnId, setNyPlombeNavnId] = React.useState<string>("");
    const [nyPlombeTypeId, setNyPlombeTypeId] = React.useState<string>("");
    const [FabrikaterTilInstrumentType, setFabrikaterTilInstrumentType] = useState<any[]>([]);
    const [ModalUtvidRettefrist, setModalUtvidRettefrist] = useState(false);
    const [AktivRettefristSaksnummer, setAktivRettefristSaksnummer] = useState("");
    const [AktivRettefrist, setAktivRettefrist] = useState("");
    const [AktivRettefristDato, setAktivRettefristDato] = useState("");
    const [AktivStansevedtak, setAktivStansevedtak] = useState("");
    const [AktivStansevedtakSaksnummer, setAktivStansevedtakSaksnummer] = useState("");
    const [ModalRettefrist, setModalRettefrist] = useState(false);
    const [ModalInternkontroll, setModalInternkontroll] = useState(false);
    const [ModalStansevedtak, setModalStansevedtak] = useState(false);
    const [AktivInternkontrollId, setAktivInternkontrollId] = useState("");
    const [AktivInternkontrollSaksnummer, setAktivInternkontrollSaksnummer] = useState("");
    const [AktivInternkontrollDato, setAktivInternkontrollDato] = useState("");
    const [ModalInternkontrollMidlertidigBrukstillatelse, setModalInternkontrollMidlertidigBrukstillatelse] = useState(false);
    const [NyFabrikat, setNyFabrikat] = useState(false);
    const [NyModell, setNyModell] = useState(false);

    const [files, setFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<UploadProgressType>({});
    const [currentUploadIndex, setCurrentUploadIndex] = useState<number>(0);
    const [fileRefId, setFileRefId] = useState<number>(0);



    const onDropFile = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        event.stopPropagation();
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, [uploading]);

    const onWipeFile = (fileName: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const { getRootProps: getRootPropsFiler, getInputProps: getInputPropsFiler, isDragActive: isDragActiveFiler } = useDropzone({
        onDrop: onDropFile,
        maxFiles: 100,
    });


    const onDropFileUtvidRettefrist = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        event.stopPropagation();
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, [uploading]);

    const onWipeFileUtvidRettefrist = (fileName: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };


    const { getRootProps: getRootPropsUtvidRettefrist, getInputProps: getInputPropsUtvidRettefrist, isDragActive } = useDropzone({
        onDrop: onDropFileUtvidRettefrist,
        maxFiles: 100,
    });

    const LastOppFilerTilMelding = async (id: number) => {
        await setCurrentUploadIndex(0);
        await handleUploadNextFile(0, id, files);
    }

    const handleUploadNextFile = async (index: number, refid: number, fileQueue: File[]) => {
        setUploading(true);

        for (let i = index; i < fileQueue.length; i++) {
            const file = fileQueue[i];
            let startTime = Date.now();
            let lastLoaded = 0;
            let lastTime = startTime;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', token);

            try {
                if (refid && refid !== 0) {
                    await axios.post(APIURL + "/filopplasting/melding/" + refid, formData, {
                        withCredentials: true,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' },

                        onUploadProgress: (event) => {
                            if (event.total) {
                                const currentTime = Date.now();
                                const loaded = event.loaded;
                                const deltaTime = (currentTime - lastTime) / 1000;
                                const deltaLoaded = loaded - lastLoaded;

                                const speed = Number(((deltaLoaded / (1024 * 1024)) / deltaTime).toFixed(2));

                                const remainingBytes = event.total - event.loaded;
                                const remainingSeconds = remainingBytes / (deltaLoaded / deltaTime);

                                let timeRemaining = '';
                                if (remainingSeconds < 60) {
                                    timeRemaining = `${Math.round(remainingSeconds)}s`;
                                } else if (remainingSeconds < 3600) {
                                    timeRemaining = `${Math.round(remainingSeconds / 60)}m ${Math.round(remainingSeconds % 60)}s`;
                                } else {
                                    const hours = Math.floor(remainingSeconds / 3600);
                                    const minutes = Math.round((remainingSeconds % 3600) / 60);
                                    timeRemaining = `${hours}h ${minutes}m`;
                                }

                                const progress = Math.round((event.loaded * 100) / event.total);
                                setUploadProgress(prevProgress => ({
                                    ...prevProgress,
                                    [file.name]: { progress, speed, timeRemaining }
                                }));

                                lastLoaded = loaded;
                                lastTime = currentTime;
                            }
                        },
                    });

                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: { progress: 100, speed: 0, timeRemaining: "" }
                    }));

                    setCurrentUploadIndex(i + 1);
                } else {
                    console.log("fileRefId er 0. Laster ikke opp filer.");
                    break;
                }
            } catch (error) {
                console.error('Upload failed:', error);
                break;
            }
        }

        setUploading(false);
    };


    const LastOppFilerTilOrdre = async (id: number, tittel: string) => {
        await setCurrentUploadIndex(0);
        await handleUploadNextFileOrdre(0, id, files, tittel);
    }

    const handleUploadNextFileOrdre = async (index: number, refid: number, fileQueue: File[], tittel: string) => {
        setUploading(true);

        for (let i = index; i < fileQueue.length; i++) {
            const file = fileQueue[i];
            let startTime = Date.now();
            let lastLoaded = 0;
            let lastTime = startTime;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', token);
            formData.append('tittel', tittel);

            try {
                if (refid && refid !== 0) {
                    await axios.post(APIURL + "/filopplasting/ordre/" + AktivOrdreId, formData, {
                        withCredentials: true,
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' },

                        onUploadProgress: (event) => {
                            if (event.total) {
                                const currentTime = Date.now();
                                const loaded = event.loaded;
                                const deltaTime = (currentTime - lastTime) / 1000;
                                const deltaLoaded = loaded - lastLoaded;

                                const speed = Number(((deltaLoaded / (1024 * 1024)) / deltaTime).toFixed(2));

                                const remainingBytes = event.total - event.loaded;
                                const remainingSeconds = remainingBytes / (deltaLoaded / deltaTime);

                                let timeRemaining = '';
                                if (remainingSeconds < 60) {
                                    timeRemaining = `${Math.round(remainingSeconds)}s`;
                                } else if (remainingSeconds < 3600) {
                                    timeRemaining = `${Math.round(remainingSeconds / 60)}m ${Math.round(remainingSeconds % 60)}s`;
                                } else {
                                    const hours = Math.floor(remainingSeconds / 3600);
                                    const minutes = Math.round((remainingSeconds % 3600) / 60);
                                    timeRemaining = `${hours}h ${minutes}m`;
                                }

                                const progress = Math.round((event.loaded * 100) / event.total);
                                setUploadProgress(prevProgress => ({
                                    ...prevProgress,
                                    [file.name]: { progress, speed, timeRemaining }
                                }));

                                lastLoaded = loaded;
                                lastTime = currentTime;
                            }
                        },
                    });

                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [file.name]: { progress: 100, speed: 0, timeRemaining: "" }
                    }));

                    setCurrentUploadIndex(i + 1);
                } else {
                    console.log("fileRefId er 0. Laster ikke opp filer.");
                    break;
                }
            } catch (error) {
                console.error('Upload failed:', error);
                break;
            }
        }

        setUploading(false);
    };



    const handleNyPlombeNavn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNyPlombeNavnId(event.target.value as string);
        const selectedItem = PlombeNavn.find((item) => item.value === value);
        setNyPlombeNavn(selectedItem?.text || "");
    };

    const handleNyPlombeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNyPlombeTypeId(event.target.value as string);
        const selectedItem = PlombeType.find((item) => item.value === value);
        setNyPlombeType(selectedItem?.text || "");
    };

    const PlombeNavn = [
        { value: "2", text: "Spenning" },
        { value: "3", text: "Koblingsboks" },
        { value: "4", text: "Display" },
        { value: "5", text: "Kalibreringskode" },
        { value: "6", text: "Indikator" },
        { value: "7", text: "Veiecelle" },
        { value: "8", text: "Annet" },
    ];

    const PlombeType = [
        { value: "1", text: "Elektronisk" },
        { value: "2", text: "Fysisk" },
    ];

    interface Instrument {
        InstrumentId: number;
        Merking?: string;
        Fabrikat?: string;
        Modell?: string;
        InstrumentType: string;
        Serienummer?: string;
        Strekkode?: string;
        InstallasjonsDato?: string;
        SisteMeldingDato?: string;
        TypeprovingssertifikatNr?: string;
        MaksKapasitet?: string;
        Plombenavn?: string;
        Plombetype?: string;
        Plombenummer?: string;
        Plombeplassering?: string;
        Status?: string;
        InstrumentTypeId: number;
        Instrumentdeltype?: string;
        Kategori?: string;
        Delinger?: string;
        instrumentdeler?: Instrumentdel[];
        meldinger?: Melding[];
        plomber?: Plombe[];
        FlereInstrumentDeler?: boolean;
        rettefristid?: string;
        stansevedtakid?: string;
        rettefristsaksnummer?: string;
        rettefristdato?: string;
        rettefristobservasjoner?: any[];
        rettefristmb?: string;
        rettefristmbdato?: string;
        stansevedtaksaksnummer?: string;
        stansevedtakobservasjoner?: any[];
        stansevedtakretting?: string;
        stansevedtakrettingdato?: string;
        rettefristpm?: string;
        rettefristordreid?: string;
    }

    interface Instrumentdel {
        InstrumentdelType: string;
        Kategori: string;
        MaksKapasitet: string;
        Delinger: string;
        Serienummer: string;
        Merking: string;
        InstrumentdelKategori: string;
        Kapasitet: string;
        KapasitetBenevning: string;
        Deling: string;
        DelingBenevning: string;
        InstrumentdelId: number;
    }

    interface Melding {
        MeldingId: number;
        Tittel: string;
        OpprettetDato: string;
        AvsenderAktor: string;
        MeldingStatus: string;
    }

    interface internkontroll {
        MeldingId: number;
        Tittel: string;
        OpprettetDato: string;
        AvsenderAktor: string;
    }

    interface Plombe {
        PlomberingId: number;
        PlombenavnId: number;
        PlomberingNavn: string;
        Plombenummer: number;
        Plassering: string;
        PlombetypeId: number;
        PlomberingType: string;
    }

    interface InstrumentGruppert {
        InstrumentType: string;
        Instruments: Instrument[];
    }

    const [rettefristaarsak, setRettefristaarsak] = useState("0");
    const handleChangeRettefristaarsak = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRettefristaarsak(event.target.value);
        RettefristFormik.setFieldValue("rettefristaarsak", event.target.value);
    }

    const handleChangePanel = (panel: string) => (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };


    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnackError(false);
    }


    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const ModalHistorikkAapne = (instrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setModalHistorikk(true);
        setAktivtInstrument(instrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
    }

    const ModalHistorikkLukk = () => {
        setModalHistorikk(false);
    }

    const ModalUtvidRettefristLukk = () => {
        UtvidRettefristFormik.resetForm();
        setModalUtvidRettefrist(false);
    }

    const ModalUtvidRettefristAapne = (instrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setModalUtvidRettefrist(true);
        setAktivtInstrument(instrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setAktivRettefristSaksnummer(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristsaksnummer || "");
        setAktivRettefrist(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristid || "");
        setAktivRettefristDato(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristdato || "");
        setAktivOrdreId(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristordreid?.toString() || "0");
    }

    const ModalStansevedtakAapne = (instrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setModalStansevedtak(true);
        setAktivtInstrument(instrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setAktivStansevedtak(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.stansevedtakid || "");
        setAktivStansevedtakSaksnummer(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.stansevedtaksaksnummer || "");
        setModalAktivePlomber(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.plomber || []);
    }

    const ModalStansevedtakLukk = () => {
        setModalStansevedtak(false);
    }

    const handleLeggTilNyPlombe = () => {
        const nyplombe = {
            navnid: nyPlombeNavnId,
            navn: nyPlombeNavn,
            typeid: nyPlombeTypeId,
            type: nyPlombeType,
            nummer: nyPlombeNummerRef.current?.value,
            plassering: nyPlombePlasseringRef.current?.value,
        };
        setNyPlombeNavnId("");
        setNyPlombeTypeId("");
        LeggtilNyPlombe(nyplombe);
    };


    const ModalEndreMerkingLukk = () => {
        EndreMerkingFormik.resetForm();
        setModalEndreMerking(false);
    };

    const ModalInstrumentDelNyAapne = (instrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setModalInstrumentDelNy(true);
        setAktivtInstrument(instrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
    };

    const ModalInstrumentDelNyLukk = () => {
        NyInstrumentDelFormik.resetForm();
        setModalInstrumentDelNy(false);
    };

    const ModalEndreMerkingAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        EndreMerkingFormik.setValues({ ...EndreMerkingFormik.values, merking: instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.Merking || "" });
        setModalEndreMerking(true);
    };

    const ModalMeldingLukk = () => {
        setModalMelding(false);
        setNyePlomber([]);
        setPlombeSlett([]);
    };

    const ModalMeldingAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalAktivePlomber(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.plomber || []);
        setModalMelding(true);
    };


    const ModalRettefristAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setAktivRettefrist(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristid || "");
        setAktivRettefristSaksnummer(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristsaksnummer || "");
        setModalAktivePlomber(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.plomber || []);
        setAktivOrdreId(instrumenterGruppert[instrumentType]?.Instruments[InstrumentIndex]?.rettefristordreid?.toString() || "0");
        setModalRettefrist(true);
    };

    const ModalRettefristLukk = () => {
        setModalRettefrist(false);
    };

    const ModalInternkontrollAapne = (meldingid: number, instrumentid: number, saksnummer: string, frist: string) => {
        setModalInternkontroll(true);
        setAktivInternkontrollId(meldingid.toString());
        setAktivtInstrument(instrumentid);
        setAktivInternkontrollSaksnummer(saksnummer);
        setAktivInternkontrollDato(frist);
    };

    const ModalInternkontrollLukk = () => {
        setModalInternkontroll(false);
    };


    const ModalInternkontrollMidlertidigBrukstillatelseAapne = (meldingid: number, instrumentid: number, saksnummer: string, frist: string) => {
        setModalInternkontrollMidlertidigBrukstillatelse(true);
        setAktivtInstrument(instrumentid);
        setAktivInternkontrollId(meldingid.toString());
        setAktivInternkontrollSaksnummer(saksnummer);
        setAktivInternkontrollDato(frist);
    }

    const ModalInternkontrollMidlertidigBrukstillatelseLukk = () => {
        setModalInternkontrollMidlertidigBrukstillatelse(false);
    }

    const LeggtilNyPlombe = async (nyplombe: any) => {
        var nypltype = nyPlombeType;
        var nyplnavn = nyPlombeNavn;
        var nyplnavnid = nyplombe.navnid;
        var nypltypeid = nyplombe.typeid;
        var nyplnummer = nyplombe.nummer;
        var nyplplassering = nyplombe.plassering;
        setNyePlomber([...NyePlomber, { nyplnavnid: nyplnavnid, nyplnavn: nyplnavn, nypltype: nypltype, nypltypeid: nypltypeid, nyplnummer: nyplnummer, nyplplassering: nyplplassering }]);
        nyPlombeNavnRef.current.value = "";
        nyPlombeTypeRef.current.value = "";
        nyPlombeNummerRef.current.value = "";
        nyPlombePlasseringRef.current.value = "";
    }

    const handleCheckSlettNyPlombe = (data: any) => {
        if (data.target.checked) {
            setNyePlomber(
                NyePlomber.filter((plomber: any, indexnypl: number) =>
                    indexnypl !== parseInt(data.target.value)
                )
            );
        }
    }

    const handleCheckSlettPlombe = (data: any) => {
        if (data.target.checked) {
            plombeSlett.push(data.target.value);
            const ModPlomber = ModalAktivePlomber.map((plomber, indexpl) => {
                if (plomber.PlomberingId === parseInt(data.target.value)) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: true,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
        } else {
            const ModPlomber = ModalAktivePlomber.map(plomber => {
                if (plomber.PlomberingId === parseInt(data.target.value)) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: false,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
            plombeSlett.splice(plombeSlett.indexOf(parseInt(data.target.value)), 1);
        }
    }


    const EndreInstrumentValidationSchema = Yup.object({
        merking: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Merking er obligatorisk'),
    });

    const EndreMerkingFormik = useFormik({
        initialValues: {
            merking: InstrumentMerking,
        },
        validationSchema: EndreInstrumentValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Redigerer instrument...");
            setisLoading(true);
            ModalEndreMerkingLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('Merking', values.merking || "");
            await axios.post(APIURL + "/innsending/instrumentmerking", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument merking endret");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under endring av instrument merking. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            EndreMerkingFormik.resetForm();
        },
    });


    const UtvidRettefristFormikValidationSchema = Yup.object({
        // dato: Yup.string().required('Dato er obligatorisk'),
        // kommentar: Yup.string().min(10, 'Må ha minimum 10 tegn').required('Kommentar er obligatorisk'),
    });

    const UtvidRettefristFormik = useFormik({
        initialValues: {
            saksnummer: "",
            dato: moment().format('YYYY-MM-DD'),
            kommentar: "",
        },
        validationSchema: UtvidRettefristFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender inn søknad om midlertidig brukstillatelse...");
            setisLoading(true);
            ModalUtvidRettefristLukk();
            const instformdata = new FormData();
            instformdata.append('instrumentid', AktivtInstrument.toString());
            instformdata.append('rettefristid', AktivRettefrist.toString());
            instformdata.append('saksnummer', values.saksnummer);
            instformdata.append('dato', values.dato);
            instformdata.append('kommentar', values.kommentar);
            await axios.post(APIURL + "/innsending/utvidetbrukstillatelse", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                        await LastOppFilerTilOrdre(parseInt(AktivOrdreId), "Søknad om midlertidig brukstillatelse");
                    }

                    setSnackMelding("Søknad om midlertidig brukstillatelse sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av søknad om midlertidig brukstillatelse. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            UtvidRettefristFormik.resetForm();
        },
    });


    const MeldingFormikValidationSchema = Yup.object({
        installasjonsdato: Yup.string().required('Installasjonsdato/reparasjonsdato er obligatorisk'),
        aarsaktilplombebrudd: Yup.string().required('Aarsak til plombebrudd er obligatorisk'),
        reparasjonsmelding: Yup.string().min(10, 'Må ha minimum 10 tegn').required('Reparasjonsmelding er obligatorisk'),
        valideringsluttkontroll: Yup.string().min(10, 'Må ha minimum 10 tegn').required('Valideringsluttkontroll er obligatorisk'),
        kommentar: Yup.string(),
    });

    const MeldingFormik = useFormik({
        initialValues: {
            melding: "",
            installasjonsdato: moment().format('YYYY-MM-DD'),
            aarsaktilplombebrudd: "",
            reparasjonsmelding: "",
            valideringsluttkontroll: "",
            kommentar: "",
        },
        validationSchema: MeldingFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender melding...");
            setisLoading(true);
            ModalMeldingLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('dato', values.installasjonsdato);
            instformdata.append('aarsak', values.aarsaktilplombebrudd);
            instformdata.append('reparasjonsmelding', values.reparasjonsmelding);
            instformdata.append('valideringsluttkontroll', values.valideringsluttkontroll);
            instformdata.append('kommentar', values.kommentar);
            instformdata.append('nyeplomber', JSON.stringify(NyePlomber));
            instformdata.append('plombeslett', JSON.stringify(plombeSlett));
            await axios.post(APIURL + "/innsending/melding/" + AktivtInstrument, instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                    }

                    setSnackMelding("Meldning sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av melding. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            MeldingFormik.resetForm();
        },
    });


    // Stansevedtak
    const StansevedtakFormikValidationSchema = Yup.object({
    });

    const StansevedtakFormik = useFormik({
        initialValues: {
            rettefristaarsak: 0,
            rettingutfort: "",
            stansevedtakbeskrivelse: "",
            stansevedtakid: 0,
        },
        validationSchema: StansevedtakFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender melding...");
            setisLoading(true);
            ModalStansevedtakLukk();
            const stansevedtakformdata = new FormData();
            stansevedtakformdata.append('instrumentid', AktivtInstrument.toString());
            stansevedtakformdata.append('stansevedtakid', AktivStansevedtak.toString());
            stansevedtakformdata.append('stansevedtakbeskrivelse', values.stansevedtakbeskrivelse);
            stansevedtakformdata.append('rettingutfort', rettefristaarsak);
            stansevedtakformdata.append('rettingsutfortdato', moment().format('YYYY-MM-DD'));
            stansevedtakformdata.append('nyeplomber', JSON.stringify(NyePlomber));
            stansevedtakformdata.append('plombeslett', JSON.stringify(plombeSlett));
            await axios.post(APIURL + "/innsending/stansevedtak", stansevedtakformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                    }

                    setSnackMelding("Melding om utført retting av stansesedtak sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av melding. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            setAktivStansevedtak("");
            setPlombeSlett([]);
            setNyePlomber([]);
            RettefristFormik.resetForm();
        },
    });

    // Internkontroll midlertidig brukstillatelse skjema

    const InternkontrollMidlertidigBrukstillatelseFormikValidationSchema = Yup.object({
    });


    const InternkontrollMidlertidigBrukstillatelseFormik = useFormik({
        initialValues: {
            dato: moment().format('YYYY-MM-DD'),
            kommentar: "",
        },
        validationSchema: InternkontrollMidlertidigBrukstillatelseFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender melding...");
            setisLoading(true);
            ModalInternkontrollMidlertidigBrukstillatelseLukk();
            const internkontrollformdata = new FormData();
            internkontrollformdata.append('instrumentid', AktivtInstrument.toString());
            internkontrollformdata.append('meldingid', AktivInternkontrollId.toString());
            internkontrollformdata.append('dato', values.dato);
            internkontrollformdata.append('kommentar', values.kommentar);
            await axios.post(APIURL + "/innsending/internkontrollmidlertidigbrukstillatelse", internkontrollformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                    }

                    setSnackMelding("Melding om midlertidig brukstillatelse sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av melding om midlertidig brukstillatelse. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            setAktivInternkontrollId("");
            setAktivInternkontrollDato("");
            setAktivInternkontrollSaksnummer("");
            InternkontrollMidlertidigBrukstillatelseFormik.resetForm();
        },
    });

    // Internkontroll skjema

    const InternkontrollFormikValidationSchema = Yup.object({
    });

    const InternkontrollFormik = useFormik({
        initialValues: {
            rettingbeskrivelse: "",
        },
        validationSchema: InternkontrollFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender melding...");
            setisLoading(true);
            ModalInternkontrollLukk();
            const internkontrollformdata = new FormData();
            internkontrollformdata.append('instrumentid', AktivtInstrument.toString());
            internkontrollformdata.append('meldingid', AktivInternkontrollId.toString());
            internkontrollformdata.append('rettingbeskrivelse', values.rettingbeskrivelse);
            await axios.post(APIURL + "/innsending/internkontroll", internkontrollformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                    }

                    setSnackMelding("Melding om utført retting av internkontroll sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av melding. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            setAktivInternkontrollId("");
            setAktivInternkontrollDato("");
            setAktivInternkontrollSaksnummer("");
            InternkontrollFormik.resetForm();
        },
    });


    // Rettefrist skjema
    const RettefristFormikValidationSchema = Yup.object({
    });


    const RettefristFormik = useFormik({
        initialValues: {
            rettingbeskrivelse: "",
            rettefristaarsak: 0,
        },
        validationSchema: RettefristFormikValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Sender melding...");
            setisLoading(true);
            ModalRettefristLukk();
            const instformdata = new FormData();
            instformdata.append('instrumentid', AktivtInstrument.toString());
            instformdata.append('rettefristid', AktivRettefrist.toString());
            instformdata.append('rettefristaarsak', rettefristaarsak);
            instformdata.append('rettingbeskrivelse', values.rettingbeskrivelse);
            instformdata.append('nyeplomber', JSON.stringify(NyePlomber));
            instformdata.append('plombeslett', JSON.stringify(plombeSlett));
            await axios.post(APIURL + "/innsending/rettefrist", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                        await LastOppFilerTilOrdre(parseInt(AktivOrdreId), "Dokumentasjon på utført retting av rettetfrist");
                    }

                    setSnackMelding("Melding om utført retting av rettetfrist sendt");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under sending av melding. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            setAktivRettefrist("");
            setPlombeSlett([]);
            setNyePlomber([]);
            RettefristFormik.resetForm();
        },
    });


    const NyInstrumentDelValidationSchema = Yup.object({
        instrumenttype: Yup.string().required('Instrumenttype er obligatorisk'),
        instrumentdelType: Yup.string().required('Instrumentdeltype er obligatorisk'),
        instrumentdelKategori: Yup.string().required('Kategori er obligatorisk'),
        makskapasitet: Yup.string().required('Maks kapasitet er obligatorisk'),
        makskapasitetbenevnelse: Yup.string().required('Maks kapasitet benevnelse er obligatorisk'),
        delinger: Yup.string().required('Deling er obligatorisk'),
        delingermaaleenhet: Yup.string().required('Deling maaleenhet er obligatorisk'),
        serienummer: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Serienummer er obligatorisk'),
        merking: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Merking er obligatorisk'),
    });

    const NyInstrumentDelFormik = useFormik({
        initialValues: {
            instrumenttype: AktivInstrumentType,
            instrumentdelType: "",
            instrumentdelKategori: "",
            makskapasitet: "",
            makskapasitetbenevnelse: "",
            delinger: "",
            delingermaaleenhet: "",
            serienummer: "",
            merking: "",
        },
        validationSchema: NyInstrumentDelValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Oppretter ny instrumentdel...");
            setisLoading(true);
            ModalInstrumentDelNyLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('InstrumentTypeId', values.instrumenttype);
            instformdata.append('serienummer', values.serienummer);
            instformdata.append('merking', values.merking);
            instformdata.append('kapasitet', values.makskapasitet);
            instformdata.append('delinger', values.delinger);
            instformdata.append('kapasitetBenevningId', values.makskapasitetbenevnelse);
            instformdata.append('delingBenevningId', values.delingermaaleenhet);
            instformdata.append('instrumentdelTypeId', values.instrumentdelType);
            instformdata.append('instrumentdelKategoriId', values.instrumentdelKategori);
            await axios.post(APIURL + "/innsending/instrumentdelny", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrumentdel lagt til");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under lagring av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            NyInstrumentDelFormik.resetForm();
        },
    });

    useEffect(() => {
        if (ModalInstrumentDelNy) {
            NyInstrumentDelFormik.resetForm({
                values: {
                    instrumenttype: AktivInstrumentType,
                    instrumentdelType: "",
                    instrumentdelKategori: "",
                    makskapasitet: "",
                    makskapasitetbenevnelse: "",
                    delinger: "",
                    delingermaaleenhet: "",
                    serienummer: "",
                    merking: "",
                }
            });
        }
    }, [ModalInstrumentDelNy]);


    const handleNyttInstrumentChangeType = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        NyttInstrumentFormik.setFieldValue('instrumenttype', value);
        NyttInstrumentFormik.setFieldValue('modell', '');
        NyttInstrumentFormik.handleChange(event);
        getfabrikatertilinstrumenttype(Number(value));
    };

    const handleNyttInstrumentChangeFabrikat = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        NyttInstrumentFormik.handleChange(event);
        console.log(event.target.value);
        if (event.target.value === "0") {
            setNyFabrikat(true);
        }
        getmodeller(Number(value));
    };

    const NyttInstrumentValidationSchema = Yup.object({
        instrumenttype: Yup.string().required('Instrumenttype er obligatorisk'),
        installasjonsdato: Yup.string().required('Installasjonsdato er obligatorisk'),
        fabrikat: Yup.string().required('Fabrikat er obligatorisk'),
        // modell: Yup.string().required('Modell er obligatorisk'),
        merking: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Merking er obligatorisk'),
        serienummer: Yup.string().min(3, 'Må ha minimum 3 tegn').required('Serienummer er obligatorisk'),
    });

    const NyttInstrumentFormik = useFormik({
        initialValues: {
            instrumenttype: "",
            instrumenttypenavn: "",
            installasjonsdato: moment().format('YYYY-MM-DD'),
            fabrikat: "",
            fabrikatnavn: "",
            nyfabrikat: "",
            nymodell: "",
            modell: "",
            modellnavn: "",
            merking: "",
            serienummer: "",
            makskapasitet: "",
            makskapasitetbenevningid: "",
            makskapasitetbenevning: "",
            delinger: "",
            delingbenevningid: "",
            delingbenevning: "",
        },
        validationSchema: NyttInstrumentValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Oppretter nytt instrument...");
            setisLoading(true);
            const instformdata = new FormData();
            instformdata.append('aktorid', aktorid?.toString() || "");
            instformdata.append('instrumenttypeid', values.instrumenttype);
            instformdata.append('instrumenttypenavn', values.instrumenttypenavn);
            instformdata.append('fabrikatid', values.fabrikat);
            instformdata.append('fabrikatnavn', values.fabrikatnavn);
            instformdata.append('nyfabrikat', values.nyfabrikat);
            instformdata.append('nymodell', values.nymodell);
            instformdata.append('modellid', values.modell);
            instformdata.append('modellnavn', values.modellnavn);
            instformdata.append('installasjonsdato', values.installasjonsdato);
            instformdata.append('merking', values.merking);
            instformdata.append('serienummer', values.serienummer);
            instformdata.append('makskapasitet', values.makskapasitet);
            instformdata.append('makskapasitetbenevningid', values.makskapasitetbenevningid);
            instformdata.append('makskapasitetbenevning', values.makskapasitetbenevning);
            instformdata.append('delinger', values.delinger);
            instformdata.append('delingbenevningid', values.delingbenevningid);
            instformdata.append('delingbenevning', values.delingbenevning);
            instformdata.append('plomber', JSON.stringify(NyePlomber));
            instformdata.append('tilsynsomradeid', "25");
            await axios.post(APIURL + "/innsending/instrumentny", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument lagt til");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under lagring av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            NyttInstrumentFormik.resetForm();
        },
    });

    const ReaktiveringValidationSchema = Yup.object({
        reaktiveringsaarsak: Yup.string().required('Reaktiveringsårsak er obligatorisk').min(3, 'Må ha minimum 10 tegn').matches(/^[a-zA-ZæøåÆØÅ0-9@!,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_!')
    });

    const ReaktiveringFormik = useFormik({
        initialValues: {
            reaktiveringsaarsak: "",
        },
        validationSchema: ReaktiveringValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Reaktiverer instrument...");
            setisLoading(true);
            ModalReaktiverInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('ReaktiveringsAarsak', values.reaktiveringsaarsak);
            await axios.post(APIURL + "/innsending/instrumentreaktivering", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then(async (response: any) => {

                    let res = JSON.parse(response.data);

                    if (files && files.length > 0) {
                        await LastOppFilerTilMelding(res.id);
                    }

                    setSnackMelding("Instrument reaktivert");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under reaktivering av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            ReaktiveringFormik.resetForm();
        },
    });

    const KasseringValidationSchema = Yup.object({
        kasseringsaarsak: Yup.string().required('Kasseringsårsak er obligatorisk').min(10, 'Må ha minimum 10 tegn').matches(/^[a-zA-ZæøåÆØÅ0-9@!,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_!')
    });

    const KasseringFormik = useFormik({
        initialValues: {
            kasseringsaarsak: "",
        },
        validationSchema: KasseringValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoadingInfo("Kasserer instrument...");
            setisLoading(true);
            ModalKasserInstrumentLukk();
            const instformdata = new FormData();
            instformdata.append('InstrumentId', AktivtInstrument.toString());
            instformdata.append('KasseringAarsak', values.kasseringsaarsak);
            await axios.post(APIURL + "/innsending/instrumentkassering", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    setSnackMelding("Instrument kassert");
                    setOpenSnack(true);
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under kassering av instrument. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            getaktor();
            setisLoading(false);
            KasseringFormik.resetForm();
        },
    });

    const sendSlettInstrumentDel = async () => {
        setisLoadingInfo("Sletter instrumentdel...");
        setisLoading(true);
        var formdata = {
            instrumentdelId: AktivInstrumentDelId,
        };
        await axios.post(APIURL + "/innsending/instrumentdelslett/" + AktivInstrumentDelId, formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor();
                    setSnackMelding("Instrumentdel slettet");
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Feil under sletting av instrumentdel. " + response?.data?.message);
                    setOpenSnackError(true);
                }
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding("Feil under sletting av instrumentdel. " + e?.response?.data?.message);
                setOpenSnackError(true);
            });
        getaktor();
        ModalInstrumentDelSlettLukk();
        setisLoading(false);
    }

    function getinstrumenttyper() {
        axios.get(APIURL + "/innsending/instrumenttyper", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstrumentTyper(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getbenevninger() {
        axios.get(APIURL + "/innsending/benevninger", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setBenevninger(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getfabrikater() {
        axios.get(APIURL + "/innsending/instrumentfabrikater", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setFabrikater(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getfabrikatertilinstrumenttype(instrumenttype: number) {
        axios.get(APIURL + "/innsending/instrumentfabrikater/" + instrumenttype, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                console.log(response.data);
                if (response.status === 200) {
                    setFabrikaterTilInstrumentType(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getinstrumentdelkategorier() {
        axios.get(APIURL + "/innsending/instrumentdelkategorier", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setInstrumentDelKategorier(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    function getmodeller(fabrikatId: number) {
        if (Number(fabrikatId) > 0) {
            axios.get(APIURL + "/innsending/instrumentmodeller/" + fabrikatId, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        setModeller(response.data);
                    }
                    else {
                    }
                    setisLoading(false);
                })
                .catch((e: any) => {
                    console.log(e);
                    setSnackMelding(e?.response?.data?.message);
                    setOpenSnackError(true);
                    setisLoading(false);
                });
        }
    }

    function getaktor() {
        axios.get(APIURL + "/innsending/lokasjon/" + aktorid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setAktor(response.data);
                    setAktorEier(response.data.eier);
                    setAktivAktorId(response?.data?.AktorId);
                    setInstrumenter(response.data?.instrumenter);
                    setInstrumenterKasserte(response.data?.instrumenterKasserte);
                    setInternkontroll(response.data?.internkontroll);

                    const instrumenterGruppert = response.data?.instrumenter?.reduce((acc: Record<number, InstrumentGruppert>, instrument: Instrument) => {
                        if (!acc[instrument.InstrumentTypeId]) {
                            acc[instrument.InstrumentTypeId] = {
                                InstrumentType: instrument.InstrumentType,
                                Instruments: []
                            };
                        }

                        acc[instrument.InstrumentTypeId].Instruments.push(instrument);

                        return acc;
                    }, {} as Record<number, InstrumentGruppert>);

                    setInstrumenterGruppert(instrumenterGruppert);

                    const instrumenterGruppertKasserte = response.data?.instrumenterKasserte?.reduce((acc: Record<number, InstrumentGruppert>, instrument: Instrument) => {
                        if (!acc[instrument.InstrumentTypeId]) {
                            acc[instrument.InstrumentTypeId] = {
                                InstrumentType: instrument.InstrumentType,
                                Instruments: []
                            };
                        }
                        acc[instrument.InstrumentTypeId].Instruments.push(instrument);
                        return acc;
                    }, {} as Record<number, InstrumentGruppert>);

                    setInstrumenterGruppertKasserte(instrumenterGruppertKasserte);

                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }


    const ModalInstrumentDelSlettAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number, AktivInstrumentDelIndex: number, instrumentdelId: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setAktivInstrumentDelIndex(AktivInstrumentDelIndex);
        setAktivInstrumentDelId(instrumentdelId);
        setModalInstrumentDelSlett(true);
    };

    const ModalInstrumentDelSlettLukk = () => {
        setAktivInstrumentDelId(0);
        setModalInstrumentDelSlett(false);
    };


    const ModalPlombebruddLukk = () => {
        setModalPlombebrudd(false);
    };

    const ModalPlombebruddAapne = (ValgtInstrumentId: number, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setModalPlombebrudd(true);
    };

    const ModalKasserInstrumentLukk = () => {
        KasseringFormik.resetForm();
        setModalKasserInstrument(false);
    };
    const ModalKasserInstrumentAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalKasserInstrument(true);
    };

    const ModalReaktiverInstrumentLukk = () => {
        ReaktiveringFormik.resetForm();
        setModalReaktiverInstrument(false);
    };

    const ModalReaktiverInstrumentAapne = (ValgtInstrumentId: number, instrumentType: string, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivInstrumentType(instrumentType);
        setModalReaktiverInstrument(true);
    };

    useEffect(() => {
        getaktor();
        if (instrumentTyper?.length === 0) {
            getinstrumenttyper();
        }
        if (Fabrikater?.length === 0) {
            getfabrikater();
        }
        if (instrumentDelKategorier?.length === 0) {
            getinstrumentdelkategorier();
        }
        if (Benevninger?.length === 0) {
            getbenevninger();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }} variant="h6" component="h6">
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalInstrumentDelSlett}
                            onClose={ModalInstrumentDelSlettLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <Box component="form" id="instrumentdelslettform" onSubmit={sendSlettInstrumentDel}>
                                <AppBar
                                    sx={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                        width: '100%',
                                        margin: 0,
                                        backgroundColor: "#f44336",
                                    }}
                                >
                                    <Toolbar
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                            variant="h6"
                                            component="div"
                                        >
                                            <DeleteForeverIcon /> {Text({ tid: "bekreftslettinstrumentdel" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalInstrumentDelSlettLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>

                                <DialogContent>
                                    {instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.length && instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.length < 2 ?
                                        <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" sx={{ mb: 2 }}>
                                            Du kan ikke slette instrumentdelen, da dette instrumentet kun har en instrumentdel. Legg til en ny instrumentdel først dersom du ønsker at denne instrumentdelen skal slettes.
                                        </Alert>
                                        :
                                        <>
                                            <Typography variant="body2" component="div" color="text.primary" sx={{ mb: 3 }}>
                                                Merking: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Merking}</b> | Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b> | Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b> | Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                            </Typography>
                                            <Typography variant="body1" component="div" color="text.primary">
                                                Er du sikker på at du vil slette instrumentdel merket <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.[AktivInstrumentDelIndex]?.Merking ?? ""}</b> med serienummer <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments?.[AktivtInstrumentIndex]?.instrumentdeler?.[AktivInstrumentDelIndex]?.Serienummer ?? ""}</b>?
                                            </Typography>
                                        </>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="instrumentdelslettform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <DeleteForeverIcon className='jvbtnicon' />
                                        {Text({ tid: "slettinstrumentdel" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalInstrumentDelSlettLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalKasserInstrument}
                            onClose={ModalKasserInstrumentLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <Box component="form" id="kasserinstrumentform" onSubmit={KasseringFormik.handleSubmit}>
                                <AppBar
                                    sx={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                        width: '100%',
                                        margin: 0,
                                        backgroundColor: "#f44336",
                                    }}
                                >
                                    <Toolbar
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                            variant="h6"
                                            component="div"
                                        >
                                            <DeleteForeverIcon /> {Text({ tid: "kasserinstrument" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalKasserInstrumentLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>

                                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" sx={{ mb: 2 }} variant="outlined">
                                        Bekreft kassering av instrument. Se over at informasjonen under stemmer med instrumentet du skal kassere, og oppgi årsak.
                                    </Alert>

                                    <Typography variant="body1" color="text.primary">
                                        Merking: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Merking}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                    </Typography>
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="kasseringsaarsak"
                                            label={Text({ tid: "kasseringsaarsak" })}
                                            name="kasseringsaarsak"
                                            defaultValue={KasseringFormik.values.kasseringsaarsak}
                                            onChange={KasseringFormik.handleChange}
                                            onBlur={KasseringFormik.handleBlur}
                                            error={KasseringFormik.touched.kasseringsaarsak && Boolean(KasseringFormik.errors.kasseringsaarsak)}
                                            helperText={KasseringFormik.touched.kasseringsaarsak && KasseringFormik.errors.kasseringsaarsak}
                                            required
                                        />
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="kasserinstrumentform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <DeleteForeverIcon className='jvbtnicon' />
                                        {Text({ tid: "kasserinstrument" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalKasserInstrumentLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalReaktiverInstrument}
                            onClose={ModalReaktiverInstrumentLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <Box component="form" id="reaktiverinstrumentform" onSubmit={ReaktiveringFormik.handleSubmit}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <Typography sx={{
                                            ml: 2,
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                            variant="h6"
                                            component="div">
                                            <RecyclingIcon className='jvbtnicon' /> {Text({ tid: "reaktiverinstrument" })}

                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalReaktiverInstrumentLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>

                                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" sx={{ mb: 2 }} variant="outlined">
                                        Bekreft reaktivering av instrument. Se over at informasjonen under stemmer med instrumentet du skal reaktivere, og oppgi årsak.
                                    </Alert>

                                    <Typography variant="body1" color="text.primary">
                                        Merking: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Merking}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Fabrikat: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Modell: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary">
                                        Serienummer: <b>{instrumenterGruppertKasserte[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                    </Typography>

                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="reaktiveringsaarsak"
                                            label={Text({ tid: "reaktiveringsaarsak" })}
                                            name="reaktiveringsaarsak"
                                            defaultValue={ReaktiveringFormik.values.reaktiveringsaarsak}
                                            onChange={ReaktiveringFormik.handleChange}
                                            onBlur={ReaktiveringFormik.handleBlur}
                                            error={ReaktiveringFormik.touched.reaktiveringsaarsak && Boolean(ReaktiveringFormik.errors.reaktiveringsaarsak)}
                                            helperText={ReaktiveringFormik.touched.reaktiveringsaarsak && ReaktiveringFormik.errors.reaktiveringsaarsak}
                                            required
                                        />
                                    </Grid>

                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsFiler()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsFiler()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFile(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>

                                    <Box display="flex" alignItems="center">
                                        <Checkbox required />
                                        <Typography variant="body1" color="text.primary">
                                            Måleredskapet er samsvarsvurdert og har gyldig samsvarsvurdering.
                                        </Typography>
                                    </Box>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="reaktiverinstrumentform"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <RecyclingIcon className='jvbtnicon' />
                                        {Text({ tid: "reaktiverinstrument" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalReaktiverInstrumentLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalHistorikk}
                            onClose={ModalHistorikkLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    width: '100%',
                                    margin: 0,
                                    backgroundColor: "#f44336",
                                }}
                            >
                                <Toolbar
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                        }}
                                        variant="h6"
                                        component="div"
                                    >
                                        <AccessTimeIcon /> {Text({ tid: "historikk" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalHistorikkLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Table>
                                    <TableHead>
                                        <TableCell>Dato/Tid</TableCell>
                                        <TableCell>Referanse</TableCell>
                                        <TableCell>Innsender</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableHead>
                                    {instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.meldinger && instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.meldinger.map(({ MeldingId, Tittel, OpprettetDato, MeldingStatus }: any, indexhist: number) => {
                                        return <TableRow key={"indexhist" + indexhist} sx={{ mb: 1 }}>
                                            <TableCell className='jvtablerow'>{moment(OpprettetDato).format('DD.MM.YYYY HH:mm')}</TableCell>
                                            <TableCell className='jvtablerow'>{MeldingId}</TableCell>
                                            <TableCell className='jvtablerow'>{Tittel}</TableCell>
                                            <TableCell className='jvtablerow'>{MeldingStatus}</TableCell>
                                        </TableRow>
                                    })
                                    }
                                </Table>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={ModalHistorikkLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "lukk" })}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalEndreMerking}
                            onClose={ModalEndreMerkingLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <Box component="form" id="endreinstrumentform" onSubmit={EndreMerkingFormik.handleSubmit}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                                            <EditIcon className='jvbtnicon' /> {Text({ tid: "endremerking" })}
                                        </Typography>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={ModalEndreMerkingLukk}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>
                                    <Grid size={{ xs: 12 }}>

                                        <Typography variant="body1" color="text.primary">
                                            Fabrikat: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Fabrikat}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.primary">
                                            Modell: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Modell}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.primary" gutterBottom>
                                            Serienummer: <b>{instrumenterGruppert[AktivInstrumentType]?.Instruments[AktivtInstrumentIndex]?.Serienummer}</b>
                                        </Typography>

                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="merking"
                                            label={Text({ tid: "merking" })}
                                            name="merking"
                                            defaultValue={EndreMerkingFormik.values.merking}
                                            onChange={EndreMerkingFormik.handleChange}
                                            onBlur={EndreMerkingFormik.handleBlur}
                                            error={EndreMerkingFormik.touched.merking && Boolean(EndreMerkingFormik.errors.merking)}
                                            helperText={EndreMerkingFormik.touched.merking && EndreMerkingFormik.errors.merking}
                                            required
                                        />
                                        <Typography variant="caption" color="text.secondary">
                                            Med "merking" menes "Internt instrumentnavn".
                                        </Typography>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="endreinstrumentform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "lagre" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalEndreMerkingLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalMelding}
                            onClose={ModalMeldingLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "innsendingutfortreparasjon" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalMeldingLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="meldingform" onSubmit={MeldingFormik.handleSubmit}>
                                <DialogContent>
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            type="date"
                                            margin="normal"
                                            fullWidth
                                            id="installasjonsdato"
                                            label={Text({ tid: "installasjonsreparasjonsdato" })}
                                            name="installasjonsdato"
                                            defaultValue={MeldingFormik.values.installasjonsdato}
                                            onChange={MeldingFormik.handleChange}
                                            onBlur={MeldingFormik.handleBlur}
                                            error={MeldingFormik.touched.installasjonsdato && Boolean(MeldingFormik.errors.installasjonsdato)}
                                            helperText={MeldingFormik.touched.installasjonsdato && MeldingFormik.errors.installasjonsdato}
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                        <TextField
                                            size="small"
                                            select
                                            fullWidth
                                            label={Text({ tid: "aarsak" })}
                                            name="aarsaktilplombebrudd"
                                            id="aarsaktilplombebrudd"
                                            value={MeldingFormik.values.aarsaktilplombebrudd}
                                            onChange={MeldingFormik.handleChange}
                                            onBlur={MeldingFormik.handleBlur}
                                            error={MeldingFormik.touched.aarsaktilplombebrudd && Boolean(MeldingFormik.errors.aarsaktilplombebrudd)}
                                            helperText={MeldingFormik.touched.aarsaktilplombebrudd && MeldingFormik.errors.aarsaktilplombebrudd}
                                        >
                                            <MenuItem value="1">
                                                {Text({ tid: "bytteveiecelle" })}
                                            </MenuItem>
                                            <MenuItem value="2">
                                                {Text({ tid: "reparasjon" })}
                                            </MenuItem>
                                            <MenuItem value="3">
                                                {Text({ tid: "kalibrering" })}
                                            </MenuItem>
                                            <MenuItem value="4">
                                                {Text({ tid: "service" })}
                                            </MenuItem>
                                            <MenuItem value="5">
                                                {Text({ tid: "veiefeil" })}
                                            </MenuItem>
                                            <MenuItem value="6">
                                                {Text({ tid: "annet" })}
                                            </MenuItem>
                                        </TextField>
                                    </Grid>


                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            type="text"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            id="reparasjonsmelding"
                                            label={Text({ tid: "reparasjonsmelding" })}
                                            name="reparasjonsmelding"
                                            defaultValue={MeldingFormik.values.reparasjonsmelding}
                                            onChange={MeldingFormik.handleChange}
                                            onBlur={MeldingFormik.handleBlur}
                                            error={MeldingFormik.touched.reparasjonsmelding && Boolean(MeldingFormik.errors.reparasjonsmelding)}
                                            helperText={MeldingFormik.touched.reparasjonsmelding && MeldingFormik.errors.reparasjonsmelding}
                                        />
                                    </Grid>

                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            type="text"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            id="valideringsluttkontroll"
                                            label={Text({ tid: "valideringsluttkontroll" })}
                                            name="valideringsluttkontroll"
                                            defaultValue={MeldingFormik.values.valideringsluttkontroll}
                                            onChange={MeldingFormik.handleChange}
                                            onBlur={MeldingFormik.handleBlur}
                                            error={MeldingFormik.touched.valideringsluttkontroll && Boolean(MeldingFormik.errors.valideringsluttkontroll)}
                                            helperText={MeldingFormik.touched.valideringsluttkontroll && MeldingFormik.errors.valideringsluttkontroll}
                                        />
                                    </Grid>

                                    <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                                        {Text({ tid: "plomber" })}
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                    <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                    <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                    <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                    <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                    return <TableRow key={"plrow" + indexpl} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                        <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                        <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                        <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                        <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                        <TableCell className='jvtablerow'>
                                                            <FormControlLabel
                                                                key={"plid_" + PlomberingId}
                                                                value={PlomberingId}
                                                                control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                label=""
                                                                onChange={handleCheckSlettPlombe}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                                }
                                                {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                    return <TableRow key={"nyplrow" + indexnypl} sx={{ mb: 1 }}>
                                                        <TableCell className='jvtablerow'>
                                                            {nyplnavn}
                                                        </TableCell>
                                                        <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                        <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                        <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                        <TableCell className='jvtablerow'>
                                                            <FormControlLabel
                                                                key={"nyplid_" + indexnypl}
                                                                value={indexnypl}
                                                                control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                checked={false}
                                                                label=""
                                                                onChange={handleCheckSlettNyPlombe}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Divider sx={{ mb: 2 }} />
                                    <Typography variant="h6" gutterBottom>
                                        {Text({ tid: "leggtilnyplombe" })}
                                    </Typography>
                                    <Box component="form" id="nyplomberform">
                                        <Grid container>
                                            <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                <TextField
                                                    size="small"
                                                    select
                                                    fullWidth
                                                    label="Plombenavn"
                                                    name="nyplnavn"
                                                    id="nyplnavn"
                                                    value={nyPlombeNavnId}
                                                    inputRef={nyPlombeNavnRef}
                                                    onChange={handleNyPlombeNavn}
                                                    required
                                                >
                                                    {PlombeNavn.map((item: any) => (
                                                        <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                            {item.text}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                <TextField
                                                    size="small"
                                                    select
                                                    fullWidth
                                                    label="Type"
                                                    name="nypltype"
                                                    id="nypltype"
                                                    value={nyPlombeTypeId}
                                                    inputRef={nyPlombeTypeRef}
                                                    onChange={handleNyPlombeType}
                                                    required
                                                >
                                                    {PlombeType.map((item: any) => (
                                                        <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                            {item.text}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="nyplnummer"
                                                    inputRef={nyPlombeNummerRef}
                                                    label={Text({ tid: "plombenummer" })}
                                                    name="nyplnummer"
                                                    required
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 3 }} sx={{ mb: 1 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="nyplplassering"
                                                    label={Text({ tid: "plassering" })}
                                                    name="nyplplassering"
                                                    inputRef={nyPlombePlasseringRef}
                                                    required
                                                />
                                            </Grid>
                                            <Grid sx={{ ml: 1, pt: 2 }}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleLeggTilNyPlombe}
                                                >
                                                    <AddCircleIcon className='jvbtnicon' />
                                                    {Text({ tid: "leggtil" })}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                            <Typography variant="h6" color="textSecondary" gutterBottom>
                                                Dokumentasjon
                                            </Typography>
                                            <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                                {!uploading && (
                                                    <Box
                                                        {...getRootPropsFiler()}
                                                        sx={{
                                                            border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                            borderRadius: '4px',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                            transition: 'background-color 0.2s ease-in-out',
                                                        }}
                                                    >
                                                        <input {...getInputPropsFiler()} />
                                                        <Typography variant="h6" color="textSecondary">
                                                            {isDragActive ?
                                                                "Slipp fil(ene) for å laste opp..." :
                                                                "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Box sx={{ marginTop: '20px' }}>
                                                    <Typography variant="overline" color="textSecondary">
                                                        Filer som lastes opp ({files.length}):
                                                    </Typography>
                                                    {files.map((file, indexfile) => (
                                                        <Box key={"file" + indexfile}>
                                                            <Typography variant="subtitle1">
                                                                <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                                {file.name} ({bytesToSize(file.size)})
                                                                <Button
                                                                    size="small"
                                                                    variant="outlined"
                                                                    color="error"
                                                                    onClick={() => onWipeFile(file.name)}
                                                                    sx={{ ml: 2 }}
                                                                >
                                                                    <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                                </Button>
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Paper>
                                        </Grid>


                                        <Grid size={{ xs: 12 }}>
                                            <TextField
                                                type="text"
                                                margin="normal"
                                                multiline
                                                rows={3}
                                                fullWidth
                                                id="kommentar"
                                                label={Text({ tid: "kommentar" })}
                                                name="kommentar"
                                                defaultValue={MeldingFormik.values.kommentar}
                                                onChange={MeldingFormik.handleChange}
                                                onBlur={MeldingFormik.handleBlur}
                                                error={MeldingFormik.touched.kommentar && Boolean(MeldingFormik.errors.kommentar)}
                                                helperText={MeldingFormik.touched.kommentar && MeldingFormik.errors.kommentar}
                                            />
                                        </Grid>

                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="meldingform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalMeldingLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog >




                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalRettefrist}
                            onClose={ModalRettefristLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "utfortrettefrist" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalRettefristLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="rettefristform" onSubmit={RettefristFormik.handleSubmit}>
                                <DialogContent>

                                    <Box sx={{ p: 1, mr: 2 }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            <Text tid="saksnummer" />: <b>#{instrumenter?.[AktivtInstrumentIndex]?.rettefristsaksnummer}</b> | <Text tid="meldingsid" /> <b>{instrumenter?.[AktivtInstrumentIndex]?.rettefristid}</b> | <Text tid="frist" />: <b>{moment(instrumenter?.[AktivtInstrumentIndex]?.rettefristdato).format('DD.MM.YYYY')}</b>
                                        </Typography>
                                    </Box>
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={Text({ tid: "rettingutfort" })}
                                            name="rettefristaarsak"
                                            id="rettefristaarsak"
                                            onChange={handleChangeRettefristaarsak}
                                            onBlur={RettefristFormik.handleBlur}
                                            error={RettefristFormik.touched.rettefristaarsak && Boolean(RettefristFormik.errors.rettefristaarsak)}
                                            helperText={RettefristFormik.touched.rettefristaarsak && RettefristFormik.errors.rettefristaarsak}
                                        >
                                            <MenuItem value="1" key="rettefristaarsak1">
                                                Måleredskapet er reparert
                                            </MenuItem>
                                            <MenuItem value="2" key="rettefristaarsak2">
                                                Måleredskapet er tatt ut av bruk
                                            </MenuItem>
                                            <MenuItem value="3" key="rettefristaarsak3">
                                                Erstattet med nytt ferdigsamsvarsvurdert måleredskap
                                            </MenuItem>
                                            <MenuItem value="4" key="rettefristaarsak4">
                                                Søkt ny førstegangsverifikasjon av måleredskap
                                            </MenuItem>
                                            <MenuItem value="5" key="rettefristaarsak5">
                                                Annet
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <div>
                                            <Typography sx={{ mt: 1 }}>
                                                {Text({ tid: "observasjonermaarettes" })}
                                            </Typography>
                                            <FormGroup>
                                                {instrumenter?.[AktivtInstrumentIndex]?.rettefristobservasjoner && instrumenter[AktivtInstrumentIndex].rettefristobservasjoner.map(({ ObservasjonId, Kode, Beskrivelse }: any, indexobs: number) => {
                                                    return <FormControlLabel control={<Checkbox required />} label={Beskrivelse} />
                                                })
                                                }
                                            </FormGroup>
                                        </div>
                                    )}
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            multiline
                                            rows="3"
                                            margin="normal"
                                            fullWidth
                                            id="rettingbeskrivelse"
                                            label={Text({ tid: "rettingbeskrivelse" })}
                                            name="rettingbeskrivelse"
                                            defaultValue={RettefristFormik.values.rettingbeskrivelse}
                                            onChange={RettefristFormik.handleChange}
                                            onBlur={RettefristFormik.handleBlur}
                                            error={RettefristFormik.touched.rettingbeskrivelse && Boolean(RettefristFormik.errors.rettingbeskrivelse)}
                                            helperText={RettefristFormik.touched.rettingbeskrivelse && RettefristFormik.errors.rettingbeskrivelse}
                                        />
                                    </Grid>

                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                            return <TableRow key={"plrow" + indexpl} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                                <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                                <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"plid_" + PlomberingId}
                                                                        value={PlomberingId}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                        label=""
                                                                        onChange={handleCheckSlettPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                        {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                            return <TableRow key={"nyplrow" + indexnypl} sx={{ mb: 1 }}>
                                                                <TableCell className='jvtablerow'>
                                                                    {nyplnavn}
                                                                </TableCell>
                                                                <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"nyplid_" + indexnypl}
                                                                        value={indexnypl}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                        checked={false}
                                                                        label=""
                                                                        onChange={handleCheckSlettNyPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Divider sx={{ mb: 2 }} />
                                            <Typography variant="h6" gutterBottom>
                                                {Text({ tid: "leggtilnyplombe" })}
                                            </Typography>
                                            <Box component="form" id="nyplomberform">
                                                <Grid container>
                                                    <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            fullWidth
                                                            label="Plombenavn"
                                                            name="nyplnavn"
                                                            id="nyplnavn"
                                                            value={nyPlombeNavnId}
                                                            inputRef={nyPlombeNavnRef}
                                                            onChange={handleNyPlombeNavn}
                                                            required
                                                        >
                                                            {PlombeNavn.map((item: any) => (
                                                                <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            fullWidth
                                                            label="Type"
                                                            name="nypltype"
                                                            id="nypltype"
                                                            value={nyPlombeTypeId}
                                                            inputRef={nyPlombeTypeRef}
                                                            onChange={handleNyPlombeType}
                                                            required
                                                        >
                                                            {PlombeType.map((item: any) => (
                                                                <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nyplnummer"
                                                            inputRef={nyPlombeNummerRef}
                                                            label={Text({ tid: "plombenummer" })}
                                                            name="nyplnummer"
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid size={{ xs: 3 }} sx={{ mb: 1 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nyplplassering"
                                                            label={Text({ tid: "plassering" })}
                                                            name="nyplplassering"
                                                            inputRef={nyPlombePlasseringRef}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid sx={{ ml: 1, pt: 2 }}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={handleLeggTilNyPlombe}
                                                        >
                                                            <AddCircleIcon className='jvbtnicon' />
                                                            {Text({ tid: "leggtil" })}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    )}
                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <FormControlLabel control={<Checkbox required />} label="Plombert i henhold til typeprøvingssertifikat" />
                                    )}


                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsFiler()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsFiler()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFile(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="rettefristform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalRettefristLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog >


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalInternkontroll}
                            onClose={ModalInternkontrollLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "utfortinternkontroll" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalInternkontrollLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="internkontrollform" onSubmit={InternkontrollFormik.handleSubmit}>
                                <DialogContent>

                                    <Box sx={{ p: 1, mr: 2 }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            <Text tid="saksnummer" />: <b>#{AktivInternkontrollSaksnummer}</b> | <Text tid="meldingsid" /> <b>{AktivInternkontrollId}</b> | <Text tid="frist" />: <b>{moment(AktivInternkontrollDato).format('DD.MM.YYYY')}</b>
                                        </Typography>
                                    </Box>
                                    {/* <div>
                                        <Typography sx={{ mt: 1 }}>
                                            {Text({ tid: "observasjonermaarettes" })}
                                        </Typography>
                                        <FormGroup>
                                            {instrumenter?.[AktivtInstrumentIndex]?.rettefristobservasjoner && instrumenter[AktivtInstrumentIndex].rettefristobservasjoner.map(({ ObservasjonId, Kode, Beskrivelse }: any, indexobs: number) => {
                                                return <FormControlLabel control={<Checkbox required />} label={Beskrivelse} />
                                            })
                                            }
                                        </FormGroup>
                                    </div> */}
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            multiline
                                            rows="3"
                                            margin="normal"
                                            fullWidth
                                            id="rettingbeskrivelse"
                                            label={Text({ tid: "rettingbeskrivelse" })}
                                            name="rettingbeskrivelse"
                                            defaultValue={InternkontrollFormik.values.rettingbeskrivelse}
                                            onChange={InternkontrollFormik.handleChange}
                                            onBlur={InternkontrollFormik.handleBlur}
                                            error={InternkontrollFormik.touched.rettingbeskrivelse && Boolean(InternkontrollFormik.errors.rettingbeskrivelse)}
                                            helperText={InternkontrollFormik.touched.rettingbeskrivelse && InternkontrollFormik.errors.rettingbeskrivelse}
                                        />
                                    </Grid>

                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsFiler()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsFiler()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFile(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="internkontrollform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalInternkontrollLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog >


                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalInternkontrollMidlertidigBrukstillatelse}
                            onClose={ModalInternkontrollMidlertidigBrukstillatelseLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "internkontrollmidlertidigbrukstillatelse" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalInternkontrollMidlertidigBrukstillatelseLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="internkontrollmidlertidigbrukstillatelseform" onSubmit={InternkontrollMidlertidigBrukstillatelseFormik.handleSubmit}>
                                <DialogContent>

                                    <Box sx={{ p: 1, mr: 2 }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            <Text tid="saksnummer" />: <b>#{AktivInternkontrollSaksnummer}</b> | <Text tid="meldingsid" /> <b>{AktivInternkontrollId}</b> | <Text tid="frist" />: <b>{moment(AktivInternkontrollDato).format('DD.MM.YYYY')}</b>
                                        </Typography>
                                    </Box>

                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            type="date"
                                            margin="normal"
                                            id="dato"
                                            label={Text({ tid: "rettefristdatoutsettelse" })}
                                            name="dato"
                                            defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                            onChange={InternkontrollMidlertidigBrukstillatelseFormik.handleChange}
                                            onBlur={InternkontrollMidlertidigBrukstillatelseFormik.handleBlur}
                                            error={InternkontrollMidlertidigBrukstillatelseFormik.touched.dato && Boolean(InternkontrollMidlertidigBrukstillatelseFormik.errors.dato)}
                                            helperText={InternkontrollMidlertidigBrukstillatelseFormik.touched.dato && InternkontrollMidlertidigBrukstillatelseFormik.errors.dato}
                                        />
                                        <TextField
                                            multiline
                                            rows={5}
                                            margin="normal"
                                            fullWidth
                                            id="kommentar"
                                            label={Text({ tid: "aarsakforsoknad" })}
                                            name="kommentar"
                                            defaultValue={UtvidRettefristFormik.values.kommentar}
                                            onChange={InternkontrollMidlertidigBrukstillatelseFormik.handleChange}
                                            onBlur={InternkontrollMidlertidigBrukstillatelseFormik.handleBlur}
                                            error={InternkontrollMidlertidigBrukstillatelseFormik.touched.kommentar && Boolean(InternkontrollMidlertidigBrukstillatelseFormik.errors.kommentar)}
                                            helperText={InternkontrollMidlertidigBrukstillatelseFormik.touched.kommentar && InternkontrollMidlertidigBrukstillatelseFormik.errors.kommentar}
                                        />
                                    </Grid>

                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsFiler()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsFiler()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFile(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="internkontrollmidlertidigbrukstillatelseform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalInternkontrollMidlertidigBrukstillatelseLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog >



                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalStansevedtak}
                            onClose={ModalStansevedtakLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "utfortstansevedtak" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalStansevedtakLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="stansevedtakform" onSubmit={StansevedtakFormik.handleSubmit}>
                                <DialogContent>

                                    <Box sx={{ p: 1, mr: 2 }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            <Text tid="saksnummer" />: <b>#{instrumenter?.[AktivtInstrumentIndex]?.stansevedtaksaksnummer}</b> | <Text tid="meldingsid" /> <b>{instrumenter?.[AktivtInstrumentIndex]?.stansevedtakid}</b>
                                        </Typography>
                                    </Box>
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={Text({ tid: "rettingutfort" })}
                                            name="rettingutfort"
                                            id="rettingutfort"
                                            onChange={handleChangeRettefristaarsak}
                                            onBlur={StansevedtakFormik.handleBlur}
                                            error={StansevedtakFormik.touched.rettingutfort && Boolean(StansevedtakFormik.errors.rettingutfort)}
                                            helperText={StansevedtakFormik.touched.rettingutfort && StansevedtakFormik.errors.rettingutfort}
                                        >
                                            <MenuItem value="1" key="rettefristaarsak1">
                                                Måleredskapet er reparert
                                            </MenuItem>
                                            <MenuItem value="2" key="rettefristaarsak2">
                                                Måleredskapet er tatt ut av bruk
                                            </MenuItem>
                                            <MenuItem value="3" key="rettefristaarsak3">
                                                Erstattet med nytt ferdigsamsvarsvurdert måleredskap
                                            </MenuItem>
                                            <MenuItem value="4" key="rettefristaarsak4">
                                                Søkt ny førstegangsverifikasjon av måleredskap
                                            </MenuItem>
                                            <MenuItem value="5" key="rettefristaarsak5">
                                                Annet
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <div>
                                            <Typography sx={{ mt: 1 }}>
                                                {Text({ tid: "observasjonermaarettes" })}
                                            </Typography>
                                            <FormGroup>
                                                {instrumenter?.[AktivtInstrumentIndex]?.stansevedtakobservasjoner && instrumenter[AktivtInstrumentIndex].stansevedtakobservasjoner.map(({ ObservasjonId, Kode, Beskrivelse }: any, indexobs: number) => {
                                                    return <FormControlLabel control={<Checkbox required />} label={Beskrivelse} />
                                                })
                                                }
                                            </FormGroup>
                                        </div>
                                    )}
                                    <Grid size={{ xs: 12 }}>
                                        <TextField
                                            multiline
                                            rows="3"
                                            margin="normal"
                                            fullWidth
                                            id="stansevedtakbeskrivelse"
                                            label={Text({ tid: "stansevedtakbeskrivelse" })}
                                            name="stansevedtakbeskrivelse"
                                            defaultValue={StansevedtakFormik.values.stansevedtakbeskrivelse}
                                            onChange={StansevedtakFormik.handleChange}
                                            onBlur={StansevedtakFormik.handleBlur}
                                            error={StansevedtakFormik.touched.stansevedtakbeskrivelse && Boolean(StansevedtakFormik.errors.stansevedtakbeskrivelse)}
                                            helperText={StansevedtakFormik.touched.stansevedtakbeskrivelse && StansevedtakFormik.errors.stansevedtakbeskrivelse}
                                        />
                                    </Grid>

                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                            return <TableRow key={"plrow" + indexpl} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                                <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                                <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"plid_" + PlomberingId}
                                                                        value={PlomberingId}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                        label=""
                                                                        onChange={handleCheckSlettPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                        {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                            return <TableRow key={"nyplrow" + indexnypl} sx={{ mb: 1 }}>
                                                                <TableCell className='jvtablerow'>
                                                                    {nyplnavn}
                                                                </TableCell>
                                                                <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"nyplid_" + indexnypl}
                                                                        value={indexnypl}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                        checked={false}
                                                                        label=""
                                                                        onChange={handleCheckSlettNyPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Divider sx={{ mb: 2 }} />
                                            <Typography variant="h6" gutterBottom>
                                                {Text({ tid: "leggtilnyplombe" })}
                                            </Typography>
                                            <Box component="form" id="nyplomberform">
                                                <Grid container>
                                                    <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            fullWidth
                                                            label="Plombenavn"
                                                            name="nyplnavn"
                                                            id="nyplnavn"
                                                            value={nyPlombeNavnId}
                                                            inputRef={nyPlombeNavnRef}
                                                            onChange={handleNyPlombeNavn}
                                                            required
                                                        >
                                                            {PlombeNavn.map((item: any) => (
                                                                <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            fullWidth
                                                            label="Type"
                                                            name="nypltype"
                                                            id="nypltype"
                                                            value={nyPlombeTypeId}
                                                            inputRef={nyPlombeTypeRef}
                                                            onChange={handleNyPlombeType}
                                                            required
                                                        >
                                                            {PlombeType.map((item: any) => (
                                                                <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                    {item.text}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nyplnummer"
                                                            inputRef={nyPlombeNummerRef}
                                                            label={Text({ tid: "plombenummer" })}
                                                            name="nyplnummer"
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid size={{ xs: 3 }} sx={{ mb: 1 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nyplplassering"
                                                            label={Text({ tid: "plassering" })}
                                                            name="nyplplassering"
                                                            inputRef={nyPlombePlasseringRef}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid sx={{ ml: 1, pt: 2 }}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={handleLeggTilNyPlombe}
                                                        >
                                                            <AddCircleIcon className='jvbtnicon' />
                                                            {Text({ tid: "leggtil" })}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    )}
                                    {(rettefristaarsak === "1" || rettefristaarsak === "5") && (
                                        <FormControlLabel control={<Checkbox required />} label="Plombert i henhold til typeprøvingssertifikat" />
                                    )}


                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary" gutterBottom>
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsFiler()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsFiler()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFile(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>


                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        type="submit"
                                        form="stansevedtakform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={ModalStansevedtakLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog >


                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={ModalUtvidRettefrist}
                            onClose={ModalUtvidRettefristLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "sokmidlertidigbrukstillatelse" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalUtvidRettefristLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>


                            <Box
                                id="utvidrettefristform"
                                component="form"
                                onSubmit={UtvidRettefristFormik.handleSubmit}
                            >
                                <DialogContent>
                                    <Grid container>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                            <Typography variant="body2" sx={{ mb: 2 }}>
                                                {Text({ tid: "rettefristid" })}: <b>{AktivRettefrist}</b>
                                            </Typography>
                                            <Typography variant="body2" sx={{ mb: 2 }}>
                                                {Text({ tid: "rettefristsaksnummer" })}: <b>{AktivRettefristSaksnummer}</b>
                                            </Typography>
                                            <Typography variant="body2" sx={{ mb: 2 }}>
                                                {Text({ tid: "rettefristdato" })}: <b>{moment(AktivRettefristDato).format('DD.MM.YYYY')}</b>
                                            </Typography>
                                            <TextField
                                                type="date"
                                                margin="normal"
                                                id="dato"
                                                label={Text({ tid: "rettefristdatoutsettelse" })}
                                                name="dato"
                                                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                                onChange={UtvidRettefristFormik.handleChange}
                                                onBlur={UtvidRettefristFormik.handleBlur}
                                                error={UtvidRettefristFormik.touched.dato && Boolean(UtvidRettefristFormik.errors.dato)}
                                                helperText={UtvidRettefristFormik.touched.dato && UtvidRettefristFormik.errors.dato}
                                            />
                                            <TextField
                                                multiline
                                                rows={5}
                                                margin="normal"
                                                fullWidth
                                                id="kommentar"
                                                label={Text({ tid: "aarsakforsoknad" })}
                                                name="kommentar"
                                                defaultValue={UtvidRettefristFormik.values.kommentar}
                                                onChange={UtvidRettefristFormik.handleChange}
                                                onBlur={UtvidRettefristFormik.handleBlur}
                                                error={UtvidRettefristFormik.touched.kommentar && Boolean(UtvidRettefristFormik.errors.kommentar)}
                                                helperText={UtvidRettefristFormik.touched.kommentar && UtvidRettefristFormik.errors.kommentar}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                        <Typography variant="h6" color="textSecondary">
                                            Dokumentasjon
                                        </Typography>
                                        <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                            {!uploading && (
                                                <Box
                                                    {...getRootPropsUtvidRettefrist()}
                                                    sx={{
                                                        border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                        borderRadius: '4px',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                        transition: 'background-color 0.2s ease-in-out',
                                                    }}
                                                >
                                                    <input {...getInputPropsUtvidRettefrist()} />
                                                    <Typography variant="h6" color="textSecondary">
                                                        {isDragActive ?
                                                            "Slipp fil(ene) for å laste opp..." :
                                                            "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginTop: '20px' }}>
                                                <Typography variant="overline" color="textSecondary">
                                                    Filer som lastes opp ({files.length}):
                                                </Typography>
                                                {files.map((file, indexfile) => (
                                                    <Box key={"file" + indexfile}>
                                                        <Typography variant="subtitle1">
                                                            <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                            {file.name} ({bytesToSize(file.size)})
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => onWipeFileUtvidRettefrist(file.name)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon onClick={() => onWipeFileUtvidRettefrist(file.name)} className='jvbtnicon' />
                                                            </Button>
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </Grid>

                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendsoknad" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalUtvidRettefristLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={ModalInstrumentDelNy}
                            onClose={ModalInstrumentDelNyLukk}
                            TransitionComponent={Transition}
                            sx={{
                                boxShadow: 24,
                                borderRadius: 2,
                                backdropFilter: "blur(10px)",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                            }}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ flex: 1, display: 'flex', alignItems: 'center' }} variant="h6" component="div">
                                        <AddIcon sx={{ mr: 1 }} /> {Text({ tid: "leggtilnyinstrumentdel" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalInstrumentDelNyLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Typography variant="h6" gutterBottom>
                                    Legg til ny instrumentdel
                                </Typography>

                                <Box component="form" id="nyinstrumentdelform" onSubmit={NyInstrumentDelFormik.handleSubmit}>
                                    <Grid container>

                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Instrumenttype"
                                                name="instrumenttype"
                                                id="instrumenttype"
                                                value={NyInstrumentDelFormik.values.instrumenttype || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.instrumenttype &&
                                                    Boolean(NyInstrumentDelFormik.errors.instrumenttype)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.instrumenttype &&
                                                    NyInstrumentDelFormik.errors.instrumenttype
                                                }
                                            >
                                                {(instrumentTyper || []).map((instrumentType: any) => (
                                                    <MenuItem key={instrumentType.InstrumentTypeId} value={instrumentType.InstrumentTypeId}>
                                                        {instrumentType.Beskrivelse}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "instrumentdeltype" })}
                                                name="instrumentdelType"
                                                id="instrumentdelType"
                                                value={NyInstrumentDelFormik.values.instrumentdelType || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.instrumentdelType &&
                                                    Boolean(NyInstrumentDelFormik.errors.instrumentdelType)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.instrumentdelType &&
                                                    NyInstrumentDelFormik.errors.instrumentdelType
                                                }
                                            >
                                                <MenuItem value="1">{Text({ tid: "maalekammer" })}</MenuItem>
                                                <MenuItem value="3">{Text({ tid: "generell" })}</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1, pt: 2 }}>

                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "kategoriklasse" })}
                                                name="instrumentdelKategori"
                                                id="instrumentdelKategori"
                                                value={NyInstrumentDelFormik.values.instrumentdelKategori || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.instrumentdelKategori &&
                                                    Boolean(NyInstrumentDelFormik.errors.instrumentdelKategori)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.instrumentdelKategori &&
                                                    NyInstrumentDelFormik.errors.instrumentdelKategori
                                                }
                                            >
                                                {(instrumentDelKategorier || []).map((kategori: any) => (
                                                    <MenuItem key={kategori.InstrumentDelKategoriId} value={kategori.InstrumentDelKategoriId}>
                                                        {kategori.Beskrivelse}
                                                    </MenuItem>
                                                ))}
                                            </TextField>


                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="merking"
                                                label={Text({ tid: "merking" })}
                                                name="merking"
                                                defaultValue={NyInstrumentDelFormik.values.merking}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.merking && Boolean(NyInstrumentDelFormik.errors.merking)}
                                                helperText={NyInstrumentDelFormik.touched.merking && NyInstrumentDelFormik.errors.merking}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="serienummer"
                                                label={Text({ tid: "serienummer" })}
                                                name="serienummer"
                                                defaultValue={NyInstrumentDelFormik.values.serienummer}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.serienummer && Boolean(NyInstrumentDelFormik.errors.serienummer)}
                                                helperText={NyInstrumentDelFormik.touched.serienummer && NyInstrumentDelFormik.errors.serienummer}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="makskapasitet"
                                                label={Text({ tid: "makskapasitet" })}
                                                name="makskapasitet"
                                                defaultValue={NyInstrumentDelFormik.values.makskapasitet}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.makskapasitet && Boolean(NyInstrumentDelFormik.errors.makskapasitet)}
                                                helperText={NyInstrumentDelFormik.touched.makskapasitet && NyInstrumentDelFormik.errors.makskapasitet}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "makskapasitetbenevnelse" })}
                                                name="makskapasitetbenevnelse"
                                                id="makskapasitetbenevnelse"
                                                value={NyInstrumentDelFormik.values.makskapasitetbenevnelse || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.makskapasitetbenevnelse &&
                                                    Boolean(NyInstrumentDelFormik.errors.makskapasitetbenevnelse)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.makskapasitetbenevnelse &&
                                                    NyInstrumentDelFormik.errors.makskapasitetbenevnelse
                                                }
                                            >
                                                {(Benevninger || []).map((benevning: any, index: number) => (
                                                    <MenuItem key={benevning.BenevningId} value={benevning.BenevningId}>
                                                        {benevning.Kode}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="delinger"
                                                label={Text({ tid: "delinger" })}
                                                name="delinger"
                                                defaultValue={NyInstrumentDelFormik.values.delinger}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={NyInstrumentDelFormik.touched.delinger && Boolean(NyInstrumentDelFormik.errors.delinger)}
                                                helperText={NyInstrumentDelFormik.touched.delinger && NyInstrumentDelFormik.errors.delinger}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "delingermaaleenhet" })}
                                                name="delingermaaleenhet"
                                                id="delingermaaleenhet"
                                                value={NyInstrumentDelFormik.values.delingermaaleenhet || ""}
                                                onChange={NyInstrumentDelFormik.handleChange}
                                                onBlur={NyInstrumentDelFormik.handleBlur}
                                                error={
                                                    NyInstrumentDelFormik.touched.delingermaaleenhet &&
                                                    Boolean(NyInstrumentDelFormik.errors.delingermaaleenhet)
                                                }
                                                helperText={
                                                    NyInstrumentDelFormik.touched.delingermaaleenhet &&
                                                    NyInstrumentDelFormik.errors.delingermaaleenhet
                                                }
                                            >
                                                {(Benevninger || []).map((benevning: any, index: number) => (
                                                    <MenuItem key={benevning.BenevningId} value={benevning.BenevningId}>
                                                        {benevning.Kode}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    form="nyinstrumentdelform"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    Legg til ny instrumentdel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={ModalInstrumentDelNyLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/innsending"
                            >
                                Innsending
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href={"/innsending/lokasjoner/" + aktorEier?.OrgNummer}
                            >
                                {aktorEier?.Navn}
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                {aktor?.Navn}
                            </Typography>
                        </Breadcrumbs>


                        <Grid container spacing={2} p={2}>
                            <Grid size={{ xs: 7 }}>
                                <Paper elevation={16} sx={{ pl: 2, pr: 2, pt: 1, pb: 2 }}>
                                    <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ mb: 1 }}>
                                        <Box display="flex" alignItems="center">
                                            <PlaceIcon sx={{ marginRight: '4px', fontSize: 'inherit', color: "red" }} />
                                            Lokasjon til instrument(ene) (underenhet)
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        {aktor?.Navn} {aktor?.OrgNummer}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' component="div">
                                        <HomeIcon className='jvtexticon' /> {aktor?.Besoksadresse}, {aktor?.Besokspostnummer} {aktor?.Besokssted}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' component="div">
                                        <PhoneIcon className='jvtexticon' /> {aktor?.Mobilnummer || aktor?.Mobilnummer === "" ? "ukjent" : aktor?.Mobilnummer} <EmailIcon sx={{ marginLeft: '10px' }} className='jvtexticon' /> {!aktor?.Epost || aktor?.Epost === "" ? "ukjent" : aktor?.Epost}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid size={{ xs: 5 }}>
                                <Paper elevation={16} sx={{ pl: 2, pr: 2, pt: 1, pb: 2 }}>
                                    <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ mb: 1 }}>
                                        <Box display="flex" alignItems="center">
                                            <BusinessIcon sx={{ marginRight: '4px', fontSize: 'inherit', color: "text.secondary" }} />
                                            Juridisk eier av instrument(ene)
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        {aktorEier?.Navn} ({aktorEier?.OrgNummer})
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' component="div">
                                        <HomeIcon className='jvtexticon' /> {aktorEier?.Besoksadresse}, {aktorEier?.Besokspostnummer} {aktorEier?.Besokssted}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' component="div">
                                        <PhoneIcon className='jvtexticon' /> {aktorEier?.Mobilnummer || aktorEier?.Mobilnummer === "" ? "ukjent" : aktorEier?.Mobilnummer} <EmailIcon sx={{ marginLeft: '10px' }} className='jvtexticon' /> {!aktorEier?.Epost || aktorEier?.Epost === "" ? "ukjent" : aktorEier?.Epost}
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid size={{ xs: 12 }}>
                                {internkontroll && internkontroll.length > 0 &&
                                    internkontroll.map((internkontroll, InternkontrollIndex) => (
                                        <Grid size={{ xs: 12 }} key={"internkontroll" + InternkontrollIndex} sx={{ mb: 2, mt: 2 }}>
                                            <Alert severity="warning" variant="outlined">
                                                <Typography variant="body1" component="div" gutterBottom sx={{ fontWeight: 'bold', mb: 1 }}>
                                                    Aktøren har et pålegg om retting med referanse ID: <b>{internkontroll.MeldingId}</b>, Saksnummer: <b>{internkontroll.Saksnummer}</b>. {moment(internkontroll.Frist).isAfter(moment(), 'day') ? <span>Frist for retting er <b>{moment(internkontroll.Frist).format('DD.MM.YYYY')}</b>.</span> : <span style={{ color: 'red' }}>Frist for retting var <b>{moment(internkontroll.Frist).format('DD.MM.YYYY')}</b> og har utløpt.</span>}
                                                </Typography>
                                                <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }} gutterBottom>
                                                    Brukers kontrollsystem: {internkontroll.InstrumentType} - {internkontroll.Merking}
                                                </Typography>

                                                {internkontroll.rettefristpm &&
                                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                        <Alert severity="error" variant="outlined">
                                                            <Typography variant="body2" gutterBottom>
                                                                En påminnelse om at instrumentet har en aktiv rettefrist ble sendt <b>{moment(internkontroll.rettefristpm).format('DD.MM.YYYY')}</b>.
                                                            </Typography>
                                                        </Alert>
                                                    </Grid>
                                                }

                                                {internkontroll.rettingid &&
                                                    <Alert severity="info" variant="outlined" sx={{ mb: 2 }}>
                                                        Vi har mottatt en melding om retting med referanse ID: <b>{internkontroll.rettingid}</b>. Saken er under behandling, og avsenderen vil bli kontaktet når en avgjørelse er tatt.
                                                    </Alert>
                                                }
                                                {internkontroll.mb &&
                                                    <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                        <Alert severity="info" variant="outlined">
                                                            <Typography variant="body2" gutterBottom>
                                                                Vi har mottatt en søknad om midlertidig brukstillatelse datert <b>{moment(internkontroll.mbdato).format('DD.MM.YYYY')}</b>. Referanse: <b>{internkontroll.mb}</b> Saken er under behandling, og avsenderen vil bli kontaktet når en avgjørelse er tatt.
                                                            </Typography>
                                                        </Alert>
                                                    </Grid>
                                                }
                                                <Grid container>
                                                    <Grid size={{ xs: 12 }}>
                                                        {!internkontroll.rettingid &&
                                                            <>
                                                                <Button variant="contained" color="primary" size="small" sx={{ mr: 1 }} onClick={() => ModalInternkontrollAapne(internkontroll.MeldingId, internkontroll.InstrumentId, internkontroll.Saksnummer, internkontroll.Frist)}>
                                                                    <EditIcon className='jvbtnicon' /> Meld utført retting
                                                                </Button>
                                                                {!internkontroll.mb &&
                                                                    <Button variant="contained" color="primary" size="small" sx={{ mr: 1 }} onClick={() => ModalInternkontrollMidlertidigBrukstillatelseAapne(internkontroll.MeldingId, internkontroll.InstrumentId, internkontroll.Saksnummer, internkontroll.Frist)}>
                                                                        <EditIcon className='jvbtnicon' /> Søk om midlertidig brukstillatelse
                                                                    </Button>
                                                                }
                                                            </>
                                                        }
                                                        {internkontroll.rettingid &&
                                                            <Button variant="contained" color="primary" size="small">
                                                                <FileUploadIcon className='jvbtnicon' /> Ettersende dokumentasjon
                                                            </Button>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Alert>
                                        </Grid>
                                    ))
                                }


                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<CheckIcon color="success" />} label={"Aktive instrumenter (" + instrumenter?.length + ")"} iconPosition="start" />
                                    <Tab icon={<DeleteIcon color="error" />} label={"Kasserte instrumenter (" + instrumenterKasserte?.length + ")"} iconPosition="start" />
                                    <Tab icon={<AddIcon color="info" />} label={"Legg til nytt instrument"} iconPosition="start" />
                                </Tabs>

                                {/* Aktive instrumenter tab */}
                                <TabPanel value={valueTab} index={0}>
                                    {Object.keys(instrumenterGruppert).map((key, index) => {
                                        const instrumentType = instrumenterGruppert[key];
                                        return (
                                            <div key={"instrumenterGruppert" + index}>
                                                <Box sx={{ mb: 2, mt: 2 }}>
                                                    <Typography variant="h6" component="span">
                                                        {instrumentType.InstrumentType}
                                                    </Typography>
                                                    <Typography variant="body1" component="span" color="text.secondary" sx={{ ml: 1 }}>
                                                        ({instrumentType.Instruments.length})
                                                    </Typography>
                                                </Box>

                                                {instrumentType.Instruments.map((instrument, InstrumentIndex) => (
                                                    <>
                                                        <Accordion
                                                            expanded={expanded === instrument.InstrumentId.toString()}
                                                            onChange={handleChangePanel(instrument.InstrumentId.toString())}                                                            >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                                sx={{
                                                                    backgroundColor: (instrument.stansevedtakid || moment(instrument.rettefristdato).isBefore(moment(), 'day'))
                                                                        ? '#f44336'
                                                                        : instrument.rettefristid
                                                                            ? '#ed6c02'
                                                                            : '#eeeeee'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    color="text.secondary"
                                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                                >
                                                                    {instrument.rettefristid
                                                                        ? <WarningIcon sx={{ mr: 1, color: 'white' }} />
                                                                        : (instrument.stansevedtakid || moment(instrument.rettefristdato).isBefore(moment(), 'day'))
                                                                            ? <DangerousIcon sx={{ mr: 1, color: 'white' }} />
                                                                            : <DoubleArrowIcon sx={{ color: 'green', mr: 1 }} />
                                                                    }
                                                                    {instrument.Merking} - [{instrument.Fabrikat} / {instrument.Modell}] - Serienummer: {instrument.Serienummer} - {instrument.Strekkode}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>

                                                                <Grid container>

                                                                    {instrument.stansevedtakid &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="error" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    Instrumentet har et stansevedtak og er ulovlig i bruk.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }

                                                                    {(instrument.rettefristid && moment(instrument.rettefristdato).isAfter(moment(), 'day')) &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="warning" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    Instrumentet har et pålegg om retting med referanse ID: <b>{instrument.rettefristid}</b>. Frist for retting er <b>{moment(instrument.rettefristdato).format('DD.MM.YYYY')}</b>.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }

                                                                    {(instrument.rettefristid && moment(instrument.rettefristdato).isBefore(moment(), 'day')) &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="error" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    Instrumentet hadde en aktiv rettefrist som utløp <b>{moment(instrument.rettefristdato).format('DD.MM.YYYY')}</b>. Rettefristen har nå utløpt, og instrumentet er ulovlig i bruk. Saksnummer: <b>{instrument.rettefristsaksnummer}</b>. Referanse: <b>{instrument.rettefristid}</b>.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }

                                                                    {instrument.rettefristpm &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="error" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    En påminnelse om at instrumentet har en aktiv rettefrist ble sendt <b>{moment(instrument.rettefristpm).format('DD.MM.YYYY')}</b>.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }


                                                                    {instrument.rettefristmb &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="info" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    Vi har mottatt en søknad om midlertidig brukstillatelse datert <b>{moment(instrument.rettefristmbdato).format('DD.MM.YYYY')}</b>. Referanse: <b>{instrument.rettefristmb}</b> Saken er under behandling, og avsenderen vil bli kontaktet når en avgjørelse er tatt.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }

                                                                    {instrument.stansevedtakretting &&
                                                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                                                            <Alert severity="info" variant="outlined">
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    Vi har mottatt en melding om utført retting datert {moment(instrument.stansevedtakrettingdato).format('DD.MM.YYYY')}. Referanse: {instrument.stansevedtakretting}. Saken er under behandling, og avsenderen vil bli kontaktet når en avgjørelse er tatt.
                                                                                </Typography>
                                                                            </Alert>
                                                                        </Grid>
                                                                    }




                                                                    <Grid size={{ xs: 3 }}>
                                                                        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', mb: 2 }} color="text.secondary">
                                                                            <BadgeIcon sx={{ mr: 1 }} /> {instrument.Merking} &nbsp; &nbsp;
                                                                            {(!instrument.rettefristid && !instrument.stansevedtakid) &&
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => ModalEndreMerkingAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                    sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                >
                                                                                    <EditIcon className='jvbtnicon' /> Endre
                                                                                </Button>
                                                                            }
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            Fabrikat: <b>{instrument.Fabrikat}</b><br />
                                                                            Modell: <b>{instrument.Modell}</b>
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.secondary" className='jvtexttitle' gutterBottom sx={{ mt: 2 }}>
                                                                            Instrumentdetaljer
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            Serienummer: <b>{instrument.Serienummer}</b><br />
                                                                            JV Strekkode: <b>{instrument.Strekkode}</b><br />
                                                                            Enhets-ID: <b>{instrument.InstrumentId}</b>
                                                                        </Typography>
                                                                        {instrument.instrumentdeler && instrument.instrumentdeler.length < 2 && (
                                                                            <>
                                                                                <Typography variant="subtitle2" color="text.secondary" className='jvtexttitle' gutterBottom sx={{ mt: 2 }}>
                                                                                    Kapasitet
                                                                                </Typography>
                                                                                <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                                    Maks kapasitet: <b>{instrument.instrumentdeler?.[0]?.Kapasitet || "ukjent"} {instrument.instrumentdeler?.[0]?.KapasitetBenevning || ""}</b><br />
                                                                                    Delinger: <b>{instrument.instrumentdeler?.[0]?.Deling || "ukjent"} {instrument.instrumentdeler?.[0]?.DelingBenevning || ""}</b>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid size={{ xs: 6 }}>
                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                                                                            <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                {Text({ tid: "plomber" })}
                                                                            </Typography>
                                                                        </Box>
                                                                        {instrument.plomber ?
                                                                            <TableContainer component={Paper}>
                                                                                <Table>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {instrument.plomber && instrument.plomber.length > 0 ?
                                                                                            instrument.plomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer }: any, index: number) => {
                                                                                                return <TableRow key={"plrow" + index}>
                                                                                                    <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                                                </TableRow>
                                                                                            })
                                                                                            :
                                                                                            <TableRow>
                                                                                                <TableCell className='jvtablerow' colSpan={4}><Typography variant="caption" color="text.secondary" gutterBottom>{Text({ tid: "ingenplomber" })}</Typography></TableCell>
                                                                                            </TableRow>
                                                                                        }

                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                            :
                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                <i>{Text({ tid: "ingenplomber" })}</i>
                                                                            </Typography>
                                                                        }


                                                                        {instrument.FlereInstrumentDeler && (
                                                                            <>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 5 }}>
                                                                                        <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                            {Text({ tid: "instrumentdeler" })}
                                                                                            <IconButton
                                                                                                onClick={() => ModalInstrumentDelNyAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    ml: 1,
                                                                                                    color: "white",
                                                                                                    bgcolor: "primary.main",
                                                                                                    "&:hover": { bgcolor: "primary.dark" },
                                                                                                    borderRadius: "50%",
                                                                                                }}
                                                                                            >
                                                                                                <AddIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Typography>
                                                                                    </Box>

                                                                                </Grid>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead>
                                                                                                <TableRow>
                                                                                                    <TableCell className='jvtablehead'>Merking</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Serienummer</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Kapasitet</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Delinger</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {instrument.instrumentdeler && instrument.instrumentdeler.map((instrumentdel, indexdel) => (
                                                                                                    <TableRow key={"delrow" + indexdel}>
                                                                                                        <TableCell>{instrumentdel.Merking}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Serienummer}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Kapasitet} {instrumentdel.KapasitetBenevning}</TableCell>
                                                                                                        <TableCell>{instrumentdel.Deling} {instrumentdel.DelingBenevning}</TableCell>
                                                                                                        <TableCell align="right">
                                                                                                            <Button variant="contained" color="error" size="small" sx={{ mr: 1, minWidth: '32px' }} onClick={() => ModalInstrumentDelSlettAapne(instrument.InstrumentId, key, InstrumentIndex, indexdel, instrumentdel.InstrumentdelId)}>
                                                                                                                <DeleteIcon />
                                                                                                            </Button>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            </>
                                                                        )}

                                                                    </Grid>
                                                                    <Grid size={{ xs: 2 }} sx={{ ml: 5 }}>
                                                                        <Box>
                                                                            <Box sx={{ p: 1, mr: 2 }}>
                                                                                <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                    Siste melding
                                                                                </Typography>

                                                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                    {instrument.meldinger && instrument.meldinger.length > 0 && (
                                                                                        <>
                                                                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                                                                {instrument.meldinger[0].Tittel}
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Referanse: <b>{instrument.meldinger[0].MeldingId}</b>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Dato: <b>{moment(instrument.meldinger[0].OpprettetDato).format('DD.MM.YYYY')}</b>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Status: <b>{instrument.meldinger[0].MeldingStatus}</b>
                                                                                            </Typography>
                                                                                        </>
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>

                                                                            <Button variant="contained" color="primary" onClick={() => ModalHistorikkAapne(instrument.InstrumentId, key, InstrumentIndex)}>
                                                                                <AccessTimeIcon className='jvbtnicon' /> Historikk
                                                                            </Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>

                                                                <Box key={instrument.Serienummer}>
                                                                    <Grid container spacing={2}>

                                                                        <Grid size={{ xs: 12 }}>
                                                                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                                                                                <Box display="flex" gap={1}>
                                                                                    {(!instrument.rettefristid && !instrument.stansevedtakid) && (
                                                                                        <>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                onClick={() => ModalMeldingAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                            >
                                                                                                <SendIcon className='jvbtnicon' /> Send melding
                                                                                            </Button>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="error"
                                                                                                onClick={() => ModalKasserInstrumentAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                            >
                                                                                                <DeleteForeverIcon className='jvbtnicon' /> Kasser instrument
                                                                                            </Button>
                                                                                        </>
                                                                                    )}
                                                                                    {(instrument.rettefristid) && (
                                                                                        <>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="success"
                                                                                                onClick={() => ModalRettefristAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                            >
                                                                                                Meld om utført retting
                                                                                            </Button>
                                                                                        </>
                                                                                    )}
                                                                                    {((instrument.stansevedtakid) && (!instrument.stansevedtakretting)) && (
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => ModalStansevedtakAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                        >
                                                                                            Meld om utført retting
                                                                                        </Button>
                                                                                    )}
                                                                                    {((instrument.rettefristid) && (!instrument.rettefristmb)) &&
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => ModalUtvidRettefristAapne(instrument.InstrumentId, key, InstrumentIndex)}
                                                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                        >
                                                                                            {Text({ tid: "sokmidlertidigbrukstillatelse" })}
                                                                                        </Button>
                                                                                    }
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion >
                                                    </>
                                                ))}
                                            </div>
                                        );
                                    })}
                                </TabPanel>

                                {/* Kasserte instrumenter tab */}
                                <TabPanel value={valueTab} index={1}>
                                    {Object.keys(instrumenterGruppertKasserte).map((key, index) => {
                                        const instrumentKasserteType = instrumenterGruppertKasserte[key];
                                        return (
                                            <div key={"instrumenterKasserteGruppert" + index}>
                                                <Box sx={{ mb: 2, mt: 2 }}>
                                                    <Typography variant="h6" component="span">
                                                        {instrumentKasserteType.InstrumentType}
                                                    </Typography>
                                                    <Typography variant="body1" component="span" color="text.secondary" sx={{ ml: 1 }}>
                                                        ({instrumentKasserteType.Instruments.length})
                                                    </Typography>
                                                </Box>
                                                {instrumentKasserteType.Instruments.map((instrumentKasserte, InstrumentIndex) => (
                                                    <>
                                                        <Accordion
                                                            expanded={expanded === instrumentKasserte.InstrumentId.toString()}
                                                            onChange={handleChangePanel(instrumentKasserte.InstrumentId.toString())}                                                            >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    color="text.secondary"
                                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                                >
                                                                    <DoubleArrowIcon sx={{ color: 'green', mr: 1 }} />
                                                                    {instrumentKasserte.Merking} - [{instrumentKasserte.Fabrikat} / {instrumentKasserte.Modell}] - Serienummer: {instrumentKasserte.Serienummer} - {instrumentKasserte.Strekkode}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>

                                                                <Grid container>
                                                                    <Grid size={{ xs: 3 }}>
                                                                        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', mb: 2 }} color="text.secondary">
                                                                            <BadgeIcon sx={{ mr: 1 }} /> {instrumentKasserte.Merking} &nbsp; &nbsp;
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            Fabrikat: <b>{instrumentKasserte.Fabrikat}</b><br />
                                                                            Modell: <b>{instrumentKasserte.Modell}</b>
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" color="text.secondary" className='jvtexttitle' gutterBottom sx={{ mt: 2 }}>
                                                                            Instrumentdetaljer
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            Serienummer: <b>{instrumentKasserte.Serienummer}</b><br />
                                                                            JV Strekkode: <b>{instrumentKasserte.Strekkode}</b><br />
                                                                            Enhets-ID: <b>{instrumentKasserte.InstrumentId}</b>
                                                                        </Typography>
                                                                        {instrumentKasserte.instrumentdeler && instrumentKasserte.instrumentdeler.length < 2 && (
                                                                            <>
                                                                                <Typography variant="subtitle2" color="text.secondary" className='jvtexttitle' gutterBottom sx={{ mt: 2 }}>
                                                                                    Kapasitet
                                                                                </Typography>
                                                                                <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                                    Maks kapasitet: <b>{instrumentKasserte.instrumentdeler?.[0]?.Kapasitet || "ukjent"} {instrumentKasserte.instrumentdeler?.[0]?.KapasitetBenevning || ""}</b><br />
                                                                                    Delinger: <b>{instrumentKasserte.instrumentdeler?.[0]?.Deling || "ukjent"} {instrumentKasserte.instrumentdeler?.[0]?.DelingBenevning || ""}</b>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid size={{ xs: 6 }}>
                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                                                                            <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                {Text({ tid: "plomber" })}
                                                                            </Typography>
                                                                        </Box>
                                                                        {instrumentKasserte.plomber ?
                                                                            <TableContainer component={Paper}>
                                                                                <Table>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {instrumentKasserte.plomber && instrumentKasserte.plomber.length > 0 ?
                                                                                            instrumentKasserte.plomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer }: any, index: number) => {
                                                                                                return <TableRow key={"plrow" + index}>
                                                                                                    <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                                                    <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                                                </TableRow>
                                                                                            })
                                                                                            :
                                                                                            <TableRow>
                                                                                                <TableCell className='jvtablerow' colSpan={4}><Typography variant="caption" color="text.secondary" gutterBottom>{Text({ tid: "ingenplomber" })}</Typography></TableCell>
                                                                                            </TableRow>
                                                                                        }

                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                            :
                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                <i>{Text({ tid: "ingenplomber" })}</i>
                                                                            </Typography>
                                                                        }


                                                                        {instrumentKasserte.FlereInstrumentDeler && (
                                                                            <>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 5 }}>
                                                                                        <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                            {Text({ tid: "instrumentdeler" })}
                                                                                        </Typography>
                                                                                    </Box>

                                                                                </Grid>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead>
                                                                                                <TableRow>
                                                                                                    <TableCell className='jvtablehead'>Merking</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Serienummer</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Kapasitet</TableCell>
                                                                                                    <TableCell className='jvtablehead'>Delinger</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {instrumentKasserte.instrumentdeler && instrumentKasserte.instrumentdeler.map((instrumentdel, indexdel) => (
                                                                                                    <TableRow key={"delrow" + indexdel}>
                                                                                                        <TableCell>{instrumentKasserte.Merking}</TableCell>
                                                                                                        <TableCell>{instrumentKasserte.Serienummer}</TableCell>
                                                                                                        <TableCell>{instrumentKasserte.instrumentdeler?.[0]?.Kapasitet || "ukjent"} {instrumentKasserte.instrumentdeler?.[0]?.KapasitetBenevning || ""}</TableCell>
                                                                                                        <TableCell>{instrumentKasserte.instrumentdeler?.[0]?.Deling || "ukjent"} {instrumentKasserte.instrumentdeler?.[0]?.DelingBenevning || ""}</TableCell>
                                                                                                        <TableCell align="right">
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            </>
                                                                        )}

                                                                    </Grid>
                                                                    <Grid size={{ xs: 2 }} sx={{ ml: 5 }}>
                                                                        <Box>
                                                                            <Box sx={{ p: 1, mr: 2 }}>
                                                                                <Typography display="flex" alignItems="center" variant="h6" gutterBottom>
                                                                                    Siste melding
                                                                                </Typography>

                                                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                    {instrumentKasserte.meldinger && instrumentKasserte.meldinger.length > 0 && (
                                                                                        <>
                                                                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                                                                {instrumentKasserte.meldinger[0].Tittel}
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Referanse: <b>{instrumentKasserte.meldinger[0].MeldingId}</b>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Dato: <b>{moment(instrumentKasserte.meldinger[0].OpprettetDato).format('DD.MM.YYYY')}</b>
                                                                                            </Typography>
                                                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                                                Status: <b>{instrumentKasserte.meldinger[0].MeldingStatus}</b>
                                                                                            </Typography>
                                                                                        </>
                                                                                    )}
                                                                                </Typography>
                                                                            </Box>

                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>

                                                                <Box key={instrumentKasserte.Serienummer}>
                                                                    <Grid container spacing={2}>

                                                                        <Grid size={{ xs: 12 }}>
                                                                            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                                                                                <Box display="flex" gap={1}>
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="success"
                                                                                        size="small"
                                                                                        onClick={() => ModalReaktiverInstrumentAapne(instrumentKasserte.InstrumentId, key, InstrumentIndex)}
                                                                                        sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                    >
                                                                                        <RecyclingIcon className='jvbtnicon' /> Reaktiver instrument
                                                                                    </Button>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </AccordionDetails>
                                                        </Accordion >
                                                    </>
                                                ))}
                                            </div>




                                        );
                                    })}
                                </TabPanel>

                                {/* Legg til nytt instrument tab */}
                                <TabPanel value={valueTab} index={2}>
                                    <Typography variant="h6" gutterBottom>
                                        Informasjon om ferdigsamsvarsvurdert måleredskap
                                    </Typography>
                                    <Alert severity="info" variant="outlined">
                                        Hvis det er satt inn komponenter som ikke er omfattet/beskrevet i typeprøvingssertifikatet eller enhetsverifikasjonssertifikatet, må det søkes om førstegangsverifikasjon. Hvis du ikke er produsent eller autorisert representant for produsenten av måleinstrument/målesystemet, må du kontakte din leverandør for å avklare hvem som skal bestille førstegangsverifikasjon.
                                    </Alert>
                                    <Box component="form" id="nyttinstrumentform" onSubmit={NyttInstrumentFormik.handleSubmit}>
                                        <Grid container spacing={2}>

                                            <Grid size={{ xs: 4 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="merking"
                                                    label={Text({ tid: "merking" })}
                                                    name="merking"
                                                    defaultValue={NyttInstrumentFormik.values.merking}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.merking && Boolean(NyttInstrumentFormik.errors.merking)}
                                                    helperText={NyttInstrumentFormik.touched.merking && NyttInstrumentFormik.errors.merking}
                                                />
                                            </Grid>

                                            <Grid size={{ xs: 4 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="serienummer"
                                                    label={Text({ tid: "serienummer" })}
                                                    name="serienummer"
                                                    defaultValue={NyttInstrumentFormik.values.serienummer}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.serienummer && Boolean(NyttInstrumentFormik.errors.serienummer)}
                                                    helperText={NyttInstrumentFormik.touched.serienummer && NyttInstrumentFormik.errors.serienummer}
                                                />
                                            </Grid>

                                            <Grid size={{ xs: 4 }}>
                                                <TextField
                                                    size="small"
                                                    type="date"
                                                    margin="normal"
                                                    fullWidth
                                                    id="installasjonsdato"
                                                    label="Installasjonsdato"
                                                    name="installasjonsdato"
                                                    defaultValue={NyttInstrumentFormik.values.installasjonsdato}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.installasjonsdato && Boolean(NyttInstrumentFormik.errors.installasjonsdato)}
                                                    helperText={NyttInstrumentFormik.touched.installasjonsdato && NyttInstrumentFormik.errors.installasjonsdato}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 12 }}>

                                                <Autocomplete
                                                    size="small"
                                                    fullWidth
                                                    options={instrumentTyper}
                                                    getOptionLabel={(option) => option.Beskrivelse || ""}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.InstrumentTypeId === value?.InstrumentTypeId
                                                    }
                                                    onChange={(e: any, value: any) => {
                                                        handleNyttInstrumentChangeType(e, value.InstrumentTypeId)
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "instrumenttypeid",
                                                            value ? value.InstrumentTypeId : ""
                                                        );
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "instrumenttypenavn",
                                                            value ? value.Beskrivelse : ""
                                                        );
                                                    }}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Instrumenttype"
                                                            name="instrumenttype"
                                                            error={
                                                                NyttInstrumentFormik.touched.instrumenttype &&
                                                                Boolean(NyttInstrumentFormik.errors.instrumenttype)
                                                            }
                                                            helperText={
                                                                NyttInstrumentFormik.touched.instrumenttype &&
                                                                NyttInstrumentFormik.errors.instrumenttype
                                                            }
                                                        />
                                                    )}
                                                />


                                            </Grid>
                                            <Grid size={{ xs: 4 }} sx={{ mt: 2 }}>
                                                {NyttInstrumentFormik.values.instrumenttype &&
                                                    <Autocomplete
                                                        size="small"
                                                        fullWidth
                                                        options={[
                                                            { Beskrivelse: "* Ny fabrikat", id: "0", FabrikatId: "0" }, // Add your manual option here
                                                            ...FabrikaterTilInstrumentType,
                                                        ]}
                                                        getOptionLabel={(option) => option.Beskrivelse || ""}
                                                        isOptionEqualToValue={(option, value) =>
                                                            option.InstrumentFabrikatId === value?.InstrumentFabrikatId
                                                        }
                                                        onChange={(event: any, value: any) => {
                                                            handleNyttInstrumentChangeFabrikat(event, value);
                                                            NyttInstrumentFormik.setFieldValue(
                                                                "fabrikat",
                                                                value ? value.FabrikatId : ""
                                                            );
                                                            NyttInstrumentFormik.setFieldValue(
                                                                "fabrikatnavn",
                                                                value ? value.Beskrivelse : ""
                                                            );
                                                            NyttInstrumentFormik.setFieldValue("modell", "");
                                                            getmodeller(value ? value.FabrikatId : "");
                                                        }}
                                                        onBlur={NyttInstrumentFormik.handleBlur}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Fabrikat"
                                                                name="fabrikat"
                                                                error={
                                                                    NyttInstrumentFormik.touched.fabrikat &&
                                                                    Boolean(NyttInstrumentFormik.errors.fabrikat)
                                                                }
                                                                helperText={
                                                                    NyttInstrumentFormik.touched.fabrikat &&
                                                                    NyttInstrumentFormik.errors.fabrikat
                                                                }
                                                            />
                                                        )}
                                                    />

                                                }
                                            </Grid>
                                            {NyttInstrumentFormik.values.fabrikat === "0" && (
                                                <>
                                                    <Grid size={{ xs: 4 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nyfabrikat"
                                                            label={Text({ tid: "nyfabrikatnavn" })}
                                                            name="nyfabrikat"
                                                            defaultValue={NyttInstrumentFormik.values.nyfabrikat}
                                                            onChange={NyttInstrumentFormik.handleChange}
                                                            onBlur={NyttInstrumentFormik.handleBlur}
                                                            error={NyttInstrumentFormik.touched.nyfabrikat && Boolean(NyttInstrumentFormik.errors.nyfabrikat)}
                                                            helperText={NyttInstrumentFormik.touched.nyfabrikat && NyttInstrumentFormik.errors.nyfabrikat}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ xs: 4 }}>
                                                        <TextField
                                                            size="small"
                                                            margin="normal"
                                                            fullWidth
                                                            id="nymodell"
                                                            label={Text({ tid: "nymodellnavn" })}
                                                            name="nymodell"
                                                            defaultValue={NyttInstrumentFormik.values.nymodell}
                                                            onChange={NyttInstrumentFormik.handleChange}
                                                            onBlur={NyttInstrumentFormik.handleBlur}
                                                            error={NyttInstrumentFormik.touched.nymodell && Boolean(NyttInstrumentFormik.errors.nymodell)}
                                                            helperText={NyttInstrumentFormik.touched.nymodell && NyttInstrumentFormik.errors.nymodell}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid size={{ xs: 4 }} sx={{ mt: 2 }}>
                                                {NyttInstrumentFormik.values.fabrikat && NyttInstrumentFormik.values.fabrikat !== "0" &&
                                                    <Autocomplete
                                                        size="small"
                                                        fullWidth
                                                        options={[
                                                            { Beskrivelse: "* Ny modell", id: "0", ModellId: "0" }, // Add your manual option here
                                                            ...Modeller,
                                                        ]}
                                                        getOptionLabel={(option) => option.Beskrivelse || ""}
                                                        isOptionEqualToValue={(option, value) =>
                                                            option.ModellId === value.ModellId
                                                        }
                                                        onChange={(event, value) => {
                                                            NyttInstrumentFormik.setFieldValue(
                                                                "modell",
                                                                value ? value.ModellId : ""
                                                            );
                                                            NyttInstrumentFormik.setFieldValue(
                                                                "modellnavn",
                                                                value ? value.Beskrivelse : ""
                                                            );
                                                        }}
                                                        onBlur={NyttInstrumentFormik.handleBlur}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Modell"
                                                                name="modell"
                                                                error={
                                                                    NyttInstrumentFormik.touched.modell &&
                                                                    Boolean(NyttInstrumentFormik.errors.modell)
                                                                }
                                                                helperText={
                                                                    NyttInstrumentFormik.touched.modell &&
                                                                    NyttInstrumentFormik.errors.modell
                                                                }
                                                            />
                                                        )}
                                                    />
                                                }
                                            </Grid>
                                            {NyttInstrumentFormik.values.modell && NyttInstrumentFormik.values.modell === "0" &&
                                                <Grid size={{ xs: 4 }}>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="nymodell"
                                                        label={Text({ tid: "nymodellnavn" })}
                                                        name="nymodell"
                                                        defaultValue={NyttInstrumentFormik.values.nymodell}
                                                        onChange={NyttInstrumentFormik.handleChange}
                                                        onBlur={NyttInstrumentFormik.handleBlur}
                                                        error={NyttInstrumentFormik.touched.nymodell && Boolean(NyttInstrumentFormik.errors.nymodell)}
                                                        helperText={NyttInstrumentFormik.touched.nymodell && NyttInstrumentFormik.errors.nymodell}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid size={{ xs: 3 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="makskapasitet"
                                                    label={Text({ tid: "makskapasitet" })}
                                                    name="makskapasitet"
                                                    defaultValue={NyttInstrumentFormik.values.makskapasitet}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.makskapasitet && Boolean(NyttInstrumentFormik.errors.makskapasitet)}
                                                    helperText={NyttInstrumentFormik.touched.makskapasitet && NyttInstrumentFormik.errors.makskapasitet}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 3 }} sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    size="small"
                                                    fullWidth
                                                    options={Benevninger}
                                                    getOptionLabel={(option) => option.Kode || ""}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.BenevningId === value.BenevningId
                                                    }
                                                    onChange={(e, value) => {
                                                        console.log(value);
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "makskapasitetbenevningid",
                                                            value ? value.BenevningId : ""
                                                        );
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "makskapasitetbenevning",
                                                            value ? value.Kode : ""
                                                        );
                                                    }}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={Text({ tid: "makskapasitetbenevning" })}
                                                            name="makskapasitetbenevning"
                                                            error={
                                                                NyttInstrumentFormik.touched.makskapasitetbenevning &&
                                                                Boolean(NyttInstrumentFormik.errors.makskapasitetbenevning)
                                                            }
                                                            helperText={
                                                                NyttInstrumentFormik.touched.makskapasitetbenevning &&
                                                                NyttInstrumentFormik.errors.makskapasitetbenevning
                                                            }
                                                        />
                                                    )}
                                                />

                                            </Grid>
                                            <Grid size={{ xs: 3 }}>
                                                <TextField
                                                    size="small"
                                                    margin="normal"
                                                    fullWidth
                                                    id="delinger"
                                                    label={Text({ tid: "delinger" })}
                                                    name="delinger"
                                                    defaultValue={NyttInstrumentFormik.values.delinger}
                                                    onChange={NyttInstrumentFormik.handleChange}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    error={NyttInstrumentFormik.touched.delinger && Boolean(NyttInstrumentFormik.errors.delinger)}
                                                    helperText={NyttInstrumentFormik.touched.delinger && NyttInstrumentFormik.errors.delinger}
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 3 }} sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    size="small"
                                                    fullWidth
                                                    options={Benevninger}
                                                    getOptionLabel={(option) => option.Kode || ""}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.BenevningId === value.BenevningId
                                                    }
                                                    onChange={(e, value) => {
                                                        console.log(value);
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "delingbenevningid",
                                                            value ? value.BenevningId : ""
                                                        );
                                                        NyttInstrumentFormik.setFieldValue(
                                                            "delingbenevning",
                                                            value ? value.Kode : ""
                                                        );
                                                    }}
                                                    onBlur={NyttInstrumentFormik.handleBlur}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={Text({ tid: "delingbenevning" })}
                                                            name="delingbenevning"
                                                            error={
                                                                NyttInstrumentFormik.touched.delingbenevning &&
                                                                Boolean(NyttInstrumentFormik.errors.delingbenevning)
                                                            }
                                                            helperText={
                                                                NyttInstrumentFormik.touched.delingbenevning &&
                                                                NyttInstrumentFormik.errors.delingbenevning
                                                            }
                                                        />
                                                    )}
                                                />

                                            </Grid>
                                        </Grid>

                                        <Typography variant="h6" gutterBottom>
                                            {Text({ tid: "plomber" })}
                                        </Typography>


                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                        <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                        <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                        <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                        <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, PlomberingNavn, PlomberingType, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                        return <TableRow key={"plrow" + indexpl} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                            <TableCell className='jvtablerow'>{PlomberingNavn}</TableCell>
                                                            <TableCell className='jvtablerow'>{PlomberingType}</TableCell>
                                                            <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                            <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                            <TableCell className='jvtablerow'>
                                                                <FormControlLabel
                                                                    key={"plid_" + PlomberingId}
                                                                    value={PlomberingId}
                                                                    control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                    label=""
                                                                    onChange={handleCheckSlettPlombe}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                    }
                                                    {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                        return <TableRow key={"nyplrow" + indexnypl} sx={{ mb: 1 }}>
                                                            <TableCell className='jvtablerow'>
                                                                {nyplnavn}
                                                            </TableCell>
                                                            <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                            <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                            <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                            <TableCell className='jvtablerow'>
                                                                <FormControlLabel
                                                                    key={"nyplid_" + indexnypl}
                                                                    value={indexnypl}
                                                                    control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                    checked={false}
                                                                    label=""
                                                                    onChange={handleCheckSlettNyPlombe}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                            {Text({ tid: "leggtilnyplombe" })}
                                        </Typography>
                                        <Box component="form" id="nyplomberform">
                                            <Grid container>
                                                <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                    <TextField
                                                        size="small"
                                                        select
                                                        fullWidth
                                                        label="Plombenavn"
                                                        name="nyplnavn"
                                                        id="nyplnavn"
                                                        value={nyPlombeNavnId}
                                                        inputRef={nyPlombeNavnRef}
                                                        onChange={handleNyPlombeNavn}
                                                        required
                                                    >
                                                        {PlombeNavn.map((item: any) => (
                                                            <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                {item.text}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid size={{ xs: 3 }} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                    <TextField
                                                        size="small"
                                                        select
                                                        fullWidth
                                                        label="Type"
                                                        name="nypltype"
                                                        id="nypltype"
                                                        value={nyPlombeTypeId}
                                                        inputRef={nyPlombeTypeRef}
                                                        onChange={handleNyPlombeType}
                                                        required
                                                    >
                                                        {PlombeType.map((item: any) => (
                                                            <MenuItem key={item.value} value={item.value} aria-label={item.text} aria-labelledby={item.text}>
                                                                {item.text}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="nyplnummer"
                                                        inputRef={nyPlombeNummerRef}
                                                        label={Text({ tid: "plombenummer" })}
                                                        name="nyplnummer"
                                                        required
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 3 }} sx={{ mb: 1 }}>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        id="nyplplassering"
                                                        label={Text({ tid: "plassering" })}
                                                        name="nyplplassering"
                                                        inputRef={nyPlombePlasseringRef}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid sx={{ ml: 1, pt: 2 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleLeggTilNyPlombe}
                                                    >
                                                        <AddCircleIcon className='jvbtnicon' />
                                                        {Text({ tid: "leggtil" })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
                                            <Typography variant="h6" color="textSecondary" gutterBottom>
                                                Dokumentasjon
                                            </Typography>
                                            {(NyttInstrumentFormik.values.fabrikat && NyttInstrumentFormik.values.fabrikat === "0") || (NyttInstrumentFormik.values.modell && NyttInstrumentFormik.values.modell === "0") &&
                                                <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
                                                    <Typography variant="body2" gutterBottom>
                                                        Når du har valgt en ny fabrikat eller modell, må du laste opp typegodkjenningssertifikat.
                                                    </Typography>
                                                </Alert>
                                            }
                                            <Paper elevation={5} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                                                {!uploading && (
                                                    <Box
                                                        {...getRootPropsFiler()}
                                                        sx={{
                                                            border: isDragActive ? '2px dashed #9e9e9e' : '2px dashed #9e9e9e',
                                                            borderRadius: '4px',
                                                            padding: '20px',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            backgroundColor: isDragActive ? '#bdbdbd' : '#e0e0e0',
                                                            transition: 'background-color 0.2s ease-in-out',
                                                        }}
                                                    >
                                                        <input {...getInputPropsFiler()} />
                                                        <Typography variant="h6" color="textSecondary">
                                                            {isDragActive ?
                                                                "Slipp fil(ene) for å laste opp..." :
                                                                "Dra og slipp fil(ene) her, eller trykk for å velge filer"}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Box sx={{ marginTop: '20px' }}>
                                                    <Typography variant="overline" color="textSecondary">
                                                        Filer som lastes opp ({files.length}):
                                                    </Typography>
                                                    {files.map((file, indexfile) => (
                                                        <Box key={"file" + indexfile}>
                                                            <Typography variant="subtitle1">
                                                                <DescriptionIcon sx={{ mr: 1 }} className='color-yellow jvtexticontitle' />
                                                                {file.name} ({bytesToSize(file.size)})
                                                                <Button
                                                                    size="small"
                                                                    variant="outlined"
                                                                    color="error"
                                                                    onClick={() => onWipeFile(file.name)}
                                                                    sx={{ ml: 2 }}
                                                                >
                                                                    <DeleteIcon onClick={() => onWipeFile(file.name)} className='jvbtnicon' />
                                                                </Button>
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Paper>
                                        </Grid>

                                        <Button
                                            type="submit"
                                            form="nyttinstrumentform"
                                            variant="contained"
                                            color="success"
                                            sx={{ mt: 2 }}
                                        >
                                            <SaveIcon className='jvbtnicon' />
                                            {Text({ tid: "leggtilnyttinstrument" })}
                                        </Button>
                                    </Box>

                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div >
            }
        </>
    )
}

export default InnsendingDetaljer;